<template>
  <vue-my-tree-select-field
    :form="form"
    :name="name"
    :title="title"
    :options="tree.root.children"
    v-bind="$attrs"
    v-on="$listeners"
    @input="input"
    :required="required"
  />
</template>

<script>
import { VueMyTreeSelectField, BaseTreeField } from "../../../components/form";

import api from "../../../api/accounts";
import { GetDataRequest } from "../../../api/request";

export default {
  extends: BaseTreeField,
  components: { VueMyTreeSelectField },
  props: {
    accountType: {
      type: Number,
      required: true,
    },
    title: {
      type: [String],
      default() {
        return this.$t("pages.accounts.account_parent");
      },
    },
  },
  data() {
    return {
      idKey: "id",
      parentKey: "account_parent",
      sortKey: "sorting",
      loadOptions: async () => {
        const request = new GetDataRequest({
          perPage: 1000,
        });
        const { data } = await api.getAccounts(
          this.currentWorkShopId,
          this.accountType,
          {
            ...request,
          }
        );
        this.sourceData = data.data;
      },
    };
  },
  methods: {
    async init(account) {
      const request = new GetDataRequest({
        perPage: 1000,
      });
      const { data } = await api.getAccounts(
        this.currentWorkShopId,
        this.accountType,
        {
          ...request,
        }
      );
      this.data = data.data;
      if (this.$isset(account)) {
        this.form[this.name] =
          typeof account === "object" ? account.id : account;
      }
    },
  },
};
</script>
import Form from "../../../../../components/form/Form";
// import { isset } from "../../../../../utils/functions";

export default class SubTaskPinnedSorting extends Form {
    parse(data) {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.sub_task_id = parseInt(data.sub_task_id);
        this.sorting = parseInt(data.sorting);
        this.is_finished = parseInt(data.is_finished) === 1 ? true : false;
        this.is_working = parseInt(data.is_working) === 1 ? true : false;

        return this;
    }

    get isFinished() {
        return this.is_finished;
    }

    get isWorking() {
        return this.is_working;
    }
}

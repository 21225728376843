import Cookies from "js-cookie";
import { TOGGLE_PROJECT_STAGE } from "../mutation-types";

import apiProject from "../../api/projects";
import {
    Task,
    SubTaskPinnedSorting,
} from "../../pages/projects/core/presentation";
import { isset } from "../../utils/functions";

const emptyTime = "00:00:00";
// state
export const state = {
    project: null,
    projectStage:
        Cookies.get("projectStage") !== null &&
        Cookies.get("projectStage") !== undefined
            ? JSON.parse(Cookies.get("projectStage"))
            : [],
    task: null,
    timerTimeFormatted: emptyTime,
    subTasksPinnedSorting: [],
    taskEditing: false,
};

// getters
export const getters = {
    projectStage: (state) => state.projectStage,
    isShowProjectStage: (state) => (project_id) => {
        let project = state.projectStage.find(
            (item) => parseInt(item.p) === parseInt(project_id)
        );
        if (project !== undefined) {
            return project.v;
        }
        return true;
    },
    task: (state) => state.task,
    timerTimeFormatted: (state) => state.timerTimeFormatted,
    timerTimeFormattedEmpty: (state) => state.timerTimeFormatted === emptyTime,
    subTasksPinnedSorting: (state) => state.subTasksPinnedSorting,
    project: (state) => state.project,
    hasProjectPermission: (state) => (name) => {
        if (isset(state.project)) {
            for (let permission of state.project.permissions) {
                if (permission === name) return true;
            }
        }
        return false;
    },
    isTaskEditing: (state) => state.taskEditing,
};

// mutations
export const mutations = {
    [TOGGLE_PROJECT_STAGE](state, { project_id, value }) {
        let project = state.projectStage.find(
            (item) => parseInt(item.p) === parseInt(project_id)
        );
        if (project !== undefined) {
            project.v = value;
        } else {
            state.projectStage.push({ p: project_id, v: value });
        }
        Cookies.set("projectStage", state.projectStage, { expires: 365 });
    },
    SET_TASK(state, task) {
        state.task = task;
    },
    SET_PROJECT(state, project) {
        state.project = project;
    },
    SET_SUB_TASKS_PINNED_SORTING(state, items) {
        state.subTasksPinnedSorting = items;
    },

    SET_TIMER_TIME(state, value) {
        state.timerTimeFormatted = value;
    },
    SET_TASK_EDITING(state, value) {
        state.taskEditing = value;
    },
};

export const actions = {
    setTaskEditing({ commit }, status) {
        commit("SET_TASK_EDITING", status);
    },
    saveToggleStage({ commit }, payload) {
        commit(TOGGLE_PROJECT_STAGE, payload);
    },
    getProjectById({ commit }, { work_shop_id, project_id }) {
        return apiProject
            .getProject(work_shop_id, { id: project_id })
            .then((response) => {
                commit("SET_PROJECT", response.data);
            });
    },
    getTaskById({ commit }, { work_shop_id, project_id, task_id }) {
        return apiProject
            .getTask(work_shop_id, project_id, { id: task_id })
            .then((response) => {
                commit("SET_TASK", new Task().parse(response.data));
            });
    },
    closeTask(state, { work_shop_id, project_id, task_id, status }) {
        return apiProject.updateProjectTaskClosed(
            work_shop_id,
            project_id,
            task_id,
            status === 1 ? 0 : 1
        );
    },

    setTimerTime({ commit }, value) {
        commit("SET_TIMER_TIME", value);
    },
    resetTimerTime({ commit }) {
        commit("SET_TIMER_TIME", emptyTime);
    },
    startTask(state, { work_shop_id, project_id, task_id }) {
        return apiProject.setInitialStatusTask(
            work_shop_id,
            project_id,
            task_id
        );
    },
    pauseTask(state, { work_shop_id, project_id, task_id }) {
        return apiProject.setPauseStatusTask(work_shop_id, project_id, task_id);
    },
    continueTask(state, { work_shop_id, project_id, task_id }) {
        return apiProject.setContinueStatusTask(
            work_shop_id,
            project_id,
            task_id
        );
    },
    finishTask(state, { work_shop_id, project_id, task_id }) {
        return apiProject.setFinalStatusTask(work_shop_id, project_id, task_id);
    },

    /**
     * Pomodoro
     */
    startWithPomodoroTimerTask(
        state,
        { work_shop_id, project_id, task_id, pomo_duration }
    ) {
        return apiProject.setInitialStatusTask(
            work_shop_id,
            project_id,
            task_id,
            {
                pomodoro_timer: true,
                pomo_duration,
            }
        );
    },
    startPomodoroTask(state, { work_shop_id, project_id, task_id }) {
        return apiProject.setPomodoroStartTask(
            work_shop_id,
            project_id,
            task_id
        );
    },
    pausePomodoroTask(state, { work_shop_id, project_id, task_id }) {
        return apiProject.setPomodoroPauseTask(
            work_shop_id,
            project_id,
            task_id
        );
    },
    continuePomodoroTask(state, { work_shop_id, project_id, task_id }) {
        return apiProject.setPomodoroContinueTask(
            work_shop_id,
            project_id,
            task_id
        );
    },
    cancelPomodoroTask(state, { work_shop_id, project_id, task_id }) {
        return apiProject.setPomodoroCancelTask(
            work_shop_id,
            project_id,
            task_id
        );
    },
    finishPomodoroTask(state, { work_shop_id, project_id, task_id }) {
        return apiProject.setPomodoroFinishTask(
            work_shop_id,
            project_id,
            task_id
        );
    },
    /**
     * ./Pomodoro
     */

    getSubTasksPinnedSorting(state, { work_shop_id, project_id, task_id }) {
        return apiProject
            .getSubTasksPinnedSorting(work_shop_id, project_id, task_id)
            .then((response) => {
                let data = response.data.data.map((x) =>
                    new SubTaskPinnedSorting().parse(x)
                );
                return data;
                // commit("SET_SUB_TASKS_PINNED_SORTING", data);
            })
            .catch((error) => {
                console.error(error);
            });
    },
    moveSubTaskPinnedSorting(
        state,
        {
            work_shop_id,
            project_id,
            task_id,
            sub_task_id,
            pinned_id,
            to_pinned_id,
        }
    ) {
        return apiProject.moveSubTaskPinnedSorting(
            work_shop_id,
            project_id,
            task_id,
            sub_task_id,
            { pinned_id, to_pinned_id }
        );
    },
    pinSubTask(state, { work_shop_id, project_id, task_id, sub_task_id }) {
        return apiProject.pinSubTask(
            work_shop_id,
            project_id,
            task_id,
            sub_task_id
        );
    },
    unPinSubTask(
        state,
        { work_shop_id, project_id, task_id, sub_task_id, pinned_id }
    ) {
        return apiProject.unPinSubTask(
            work_shop_id,
            project_id,
            task_id,
            sub_task_id,
            pinned_id
        );
    },
    toggleFinishedSubTask(
        state,
        { work_shop_id, project_id, task_id, sub_task_id, is_finished }
    ) {
        return apiProject.setFinishedSubTask(
            work_shop_id,
            project_id,
            task_id,
            {
                id: sub_task_id,
                data: { is_finished: is_finished ? 0 : 1 },
            }
        );
    },
};

<template>
  <div v-if="showFastMenu" class="dropdown">
    <a
      class="nav-link"
      id="dropDownFastMenu"
      data-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="fas fa-plus-square"></i>
    </a>
    <div class="dropdown-menu" aria-labelledby="dropDownFastMenu">
      <div v-if="showOrder">
        <router-link
          :to="{
            name: 'orders-create',
            params: { work_shop_id: currentWorkShopId },
          }"
          class="dropdown-item"
        >
          <span>
            <i class="nav-icon fas fa-folder"></i>
            {{ $t("pages.orders.create_order") }}
          </span>
        </router-link>
      </div>

      <router-link
        :to="{
          name: 'expenses-create',
          params: { work_shop_id: currentWorkShopId },
        }"
        class="dropdown-item"
        v-if="showExpense"
      >
        <span>
          <i class="nav-icon fas fa-hand-holding-usd"></i>
          {{ $t("pages.expenses.create_expense") }}
        </span>
      </router-link>

      <router-link
        :to="{
          name: 'incomes-create',
          params: { work_shop_id: currentWorkShopId },
        }"
        class="dropdown-item"
        v-if="showIncome"
      >
        <span>
          <i class="nav-icon fas fa-money-bill-alt"></i>
          {{ $t("pages.incomes.create_income") }}
        </span>
      </router-link>

      <a
        href="#"
        class="dropdown-item"
        @click="showCreateReceiptModalWindow"
        v-if="showExpense"
      >
        <i class="fa fa-plus"></i>
        {{ $t("pages.expenses.receipt.create_receipt") }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EXPENSE_RECEIPT_SHOW_CREATE_MODAL } from "../pages/events";
export default {
  computed: {
    ...mapGetters("auth", [
      "hasRole",
      "hasPermission",
      "expenseIncomeEnabled",
      "ordersEnabled",
    ]),
    ...mapGetters("auth", ["currentWorkShopId"]),
    showFastMenu() {
      return true;
    },
    showExpense() {
      return this.expenseIncomeEnabled && this.hasPermission("create_expense");
    },
    showIncome() {
      return this.expenseIncomeEnabled && this.hasPermission("create_income");
    },
    showOrder() {
      return this.ordersEnabled && this.hasPermission("create_order");
    },
  },
  methods: {
    showCreateReceiptModalWindow() {
      EventBus.fire(EXPENSE_RECEIPT_SHOW_CREATE_MODAL);
    },
  },
};
</script>

<style>
</style>
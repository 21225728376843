<template>
  <div class="wrapper">
    <nav-bar></nav-bar>

    <side-bar></side-bar>

    <child></child>

    <footer-bar></footer-bar>

    <control-side-bar />

    <receipt-create-modal />
  </div>
</template>

<script>
import NavBar from "~/components/NavBar";
import SideBar from "~/components/SideBar";
import FooterBar from "~/components/FooterBar";
import ControlSideBar from "~/components/control-sidebar/ControlSideBar";
import ReceiptCreateModal from "~/pages/expenses/ReceiptCreateModal.vue";

export default {
  name: "MainLayout",

  components: {
    ControlSideBar,
    FooterBar,
    SideBar,
    NavBar,
    ReceiptCreateModal,
  },
};
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select-field", {
    attrs: {
      title: _vm.title,
      id: _vm.id,
      form: _vm.form,
      name: _vm.name,
      label: "name",
      options: _vm.options,
      multiple: _vm.multiple,
      required: _vm.required,
    },
    on: { search: _vm.onSearch },
    scopedSlots: _vm._u(
      [
        {
          key: "no-options",
          fn: function (ref) {
            var search = ref.search
            var searching = ref.searching
            return [
              searching
                ? [
                    _c(
                      "div",
                      { staticClass: "m-1", staticStyle: { opacity: "0.5" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t("pages.providers.no_options_founded")
                            ) +
                            "\n      "
                        ),
                      ]
                    ),
                  ]
                : _c("div", { staticStyle: { opacity: "0.5" } }, [
                    _c("div", { staticClass: "m-1" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("pages.providers.provider_search")) +
                          "\n      "
                      ),
                    ]),
                  ]),
            ]
          },
        },
        {
          key: "option",
          fn: function (ref) {
            var id = ref.id
            var name = ref.name
            return [
              _c("div", { staticStyle: { margin: "0" } }, [
                _vm._v("[" + _vm._s(id) + "] " + _vm._s(name)),
              ]),
            ]
          },
        },
        _vm.showCreationButton
          ? {
              key: "list-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "text-center border-footer-field p-2",
                      on: { click: _vm.creationButtonClicked },
                    },
                    [
                      _c("i", { staticClass: "fas fa-plus text-success" }),
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("pages.providers.create_provider")) +
                          "\n    "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
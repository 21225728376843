<template>
  <vue-my-modal
    :modalTitle="$t('pages.expenses.receipt.create_receipt_title')"
    :show-progress="showProgress"
    :show-modal="showModal"
    :saveOnEnter="true"
    @[saveEvent]="onSaveButtonClicked"
    @[closeEvent]="onCloseButtonClicked"
  >
    <template v-slot:body>
      <form role="form">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <expense-date-field
                :form="form"
                name="dateExpense"
                id="receipt-date"
                :required="true"
              />
            </div>
            <div class="col-md-4">
              <currency-field
                :form="form"
                :required="true"
                name="currency"
                id="receipt-currency"
                ref="currency"
                :initWhenCreated="false"
              />
            </div>
            <div class="col-md-4">
              <expense-rate-field
                :form="form"
                :required="true"
                name="rate"
                id="receipt-rate"
                v-if="showRate"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <provider-field
                :form="form"
                name="client"
                id="receipt-provider"
                :showCreationButton="true"
                :ajaxSearching="true"
                :required="true"
              />
            </div>
            <div class="col-md-6">
              <account-list-field
                id="receipt-create-account-field"
                :form="form"
                name="account"
                ref="accounts"
                :title="$t('pages.accounts.title')"
                :account-type="accountType"
                :initWhenCreated="false"
                :required="true"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <line-name-field
                :form="form"
                name="name"
                id="manage-free-line-name-receipt"
              ></line-name-field>
            </div>
            <div class="col md-3">
              <line-price-field
                :form="form"
                name="price"
                id="receipt-free-line-price"
              />
            </div>
            <div class="col md-2">
              <line-quantity-field
                :form="form"
                name="quantity"
                id="receipt-free-line-quantity"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <discount-type-field
                :form="form"
                name="discountType"
                id="receipt-free-line-discount-type"
              />
            </div>

            <div class="col md-6">
              <line-discount-field
                :form="form"
                name="discount"
                id="receipt-free-line-discount"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <project-field
                :form="form"
                name="project"
                id="receipt-create-project-field"
                :ajaxSearching="true"
              ></project-field>
            </div>
            <div class="col-md-6">
              <taxonomy-field
                :form="form"
                name="taxonomies"
                id="receipt-taxonomy"
                :taxonomy-type="taxonomyType"
                :ajaxSearching="true"
                :multiple="true"
              ></taxonomy-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <expense-refund-type-field
                :form="form"
                ref="refundType"
                name="refundType"
                id="expense-refund-type"
                initWithDefault
              />
            </div>
            <div class="col-md-6">
              <expense-refund-value-field
                :form="form"
                name="refundValue"
                id="expense-refund-type"
              />
            </div>
          </div>
        </div>
        <!-- /.card-body -->
      </form>
    </template>
  </vue-my-modal>
</template>

<script>
import { VueMyModal, ModalForm, ModalAlert } from "../../components/modal";

import {
  EXPENSE_RECEIPT_SHOW_CREATE_MODAL,
  EXPENSE_INFO_UPDATED,
} from "../events";

import { ACCOUNT_TYPE_CREDIT, TAXONOMY_TYPE_EXPENSE } from "../constants";

import {
  CurrencyField,
  ProviderField,
  ProjectField,
  TaxonomyField,
} from "../components/fields";
import { AccountListField } from "../accounts/fields";

import {
  ExpenseDateField,
  LinePriceField,
  LineQuantityField,
  LineNameField,
  LineDiscountField,
  ExpenseRateField,
  Receipt,
  ExpenseRefundTypeField,
  ExpenseRefundValueField,
} from "./fields";

import { mapGetters } from "vuex";
import apiCurrencyExchange from "../../api/currency-exchanges";
import { formatDate } from "../../utils/functions";
import { DiscountTypeField } from "../../pages/orders/fields";

export default {
  mixins: [ModalForm],
  components: {
    VueMyModal,
    ExpenseDateField,
    CurrencyField,
    ProviderField,
    AccountListField,
    ProjectField,
    LinePriceField,
    LineQuantityField,
    LineNameField,
    LineDiscountField,
    ExpenseRateField,
    TaxonomyField,
    ExpenseRefundTypeField,
    ExpenseRefundValueField,
    DiscountTypeField,
  },
  data() {
    return {
      form: new Receipt(),
    };
  },
  computed: {
    ...mapGetters("auth", ["currency"]),
    accountType() {
      return ACCOUNT_TYPE_CREDIT;
    },
    taxonomyType() {
      return TAXONOMY_TYPE_EXPENSE;
    },
    showRate() {
      return (
        this.$isset(this.form["currency"]) &&
        this.currency.id !== this.form.currency.id
      );
    },
  },
  watch: {
    showModal(val) {
      if (val) {
        this.form.reset();
        this.form.busy = true;
        this.form.dateExpense = new Date();
        this.form.quantity = 1;
        this.$refs.currency.setOptionIds(this.currency.id);
        this.$refs.accounts.setOptionIds();
        this.$refs.refundType.setOptionIds();

        this.form.busy = false;
      }
    },
  },
  mounted() {
    this.$watch(
      (vm) => [vm.form.currency, vm.form.dateExpense],
      (val) => {
        let currency = val[0];
        let dateExpense = val[1];

        if (
          this.$isset(currency) &&
          this.$isset(dateExpense) &&
          currency.id !== this.currency.id
        ) {
          apiCurrencyExchange
            .getCurrencyExchangeByDate(currency.code, formatDate(dateExpense))
            .then((response) => {
              this.form.rate = response.data.rate;
              this.form.dateRate = this.$parseDate(response.data.dateRate);
            })
            .catch((error) => {
              if (error.response.status === 404) {
                ModalAlert.error({
                  message: this.$t("pages.expenses.not_found_exchange"),
                });
              } else {
                ModalAlert.error({
                  message: this.$t("pages.errors.500.message"),
                });
              }
              console.error(error);
            });
        } else {
          this.form.rate = null;
          this.form.dateRate = null;
        }
      },
      {
        immediate: true, // run immediately
        deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      }
    );
  },
  created() {
    EventBus.listen(EXPENSE_RECEIPT_SHOW_CREATE_MODAL, () => {
      this.form.reset();
      this.showModal = true;
    });
  },
  beforeDestroy() {
    EventBus.off(EXPENSE_RECEIPT_SHOW_CREATE_MODAL);
  },
  methods: {
    async onSaveButtonClicked() {
      if (!this.form.busy) {
        this.form.busy = true;
        await this.form.post(
          `/api/${this.currentWorkShopId}/expenses/receipts/store`,
          this.form.data()
        );
        EventBus.fire(EXPENSE_INFO_UPDATED);
        this.showModal = false;
        this.form.busy = false;
      }
    },
  },
};
</script>

<template>
  <vue-my-date-time-field
    :title="$t('pages.expenses.date_rate')"
    v-bind="$attrs"
    v-on="$listeners"
  ></vue-my-date-time-field>
</template>

<script>
import { VueMyDateTimeField } from "../../../components/form";

export default {
  components: {
    VueMyDateTimeField
  }
};
</script>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select-field",
    _vm._g(
      _vm._b(
        {
          attrs: {
            title: _vm.title,
            form: _vm.form,
            name: _vm.name,
            multiple: _vm.multiple,
            label: "name",
            options: _vm.options,
          },
          on: { search: _vm.onSearch },
          scopedSlots: _vm._u([
            {
              key: "no-options",
              fn: function (ref) {
                var searching = ref.searching
                return [
                  searching
                    ? [
                        _vm._v(
                          _vm._s(_vm.$t("pages.projects.no_options_founded"))
                        ),
                      ]
                    : _c("em", { staticStyle: { opacity: "0.5" } }, [
                        _vm._v(_vm._s(_vm.$t("pages.projects.project_search"))),
                      ]),
                ]
              },
            },
            {
              key: "option",
              fn: function (ref) {
                var id = ref.id
                var name = ref.name
                return [
                  _c("div", { staticStyle: { margin: "0" } }, [
                    _vm._v("[" + _vm._s(id) + "] " + _vm._s(name)),
                  ]),
                ]
              },
            },
          ]),
        },
        "select-field",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select-field",
    _vm._g(
      _vm._b(
        {
          attrs: {
            title: _vm.$t("pages.orders.discount_type"),
            label: "name",
            form: _vm.form,
            name: _vm.name,
            options: _vm.options,
            required: _vm.required,
          },
        },
        "select-field",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
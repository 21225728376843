import {
    PROJECT_STATUS_IS_INITIAL,
    PROJECT_STATUS_IS_FINAL
} from "../../../../constants";

export default class TaskStatus {
    parse(data) {
        this.id = parseInt(data.id);
        this.color = data.color;
        this.is_default = parseInt(data.is_default);
        this.name = data.name;
        this.status_type = parseInt(data.status_type);
        this.text_color = data.text_color;

        return this;
    }

    get isInitial() {
        return this.status_type === PROJECT_STATUS_IS_INITIAL;
    }

    get isFinal() {
        return this.status_type === PROJECT_STATUS_IS_FINAL;
    }
}

export default class TaskType {
    parse(data) {
        this.id = parseInt(data.id);
        this.name = data.name;
        this.color = data.color;
        this.variant = parseInt(data.variant);

        return this;
    }
}

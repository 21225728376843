<template>
  <aside
    id="control-sidebar"
    class="control-sidebar control-sidebar-light"
    :style="{ width: `${width}px` }"
  >
    <div style="padding: 16px; height: 100%; width: 100%">
      <button
        type="button"
        class="btn btn-default"
        data-widget="control-sidebar"
      >
        <i class="fas fa-times"></i>
      </button>
      <hr class="mb-2" />
      <div ref="sidebarpanel"></div>
    </div>
  </aside>
</template>

<script>
import {
  EXPENSE_CREATE_CONTROL_SIDEBAR_OPEN,
  INCOME_CREATE_CONTROL_SIDEBAR_OPEN,
  ORDER_CREATE_CONTROL_SIDEBAR_OPEN,
} from "../../pages/events";
import Vue from "vue";

import ControlExpenseSidebar from "../../pages/expenses/components/ControlSidebarComponent";
import ControlIncomeSidebar from "../../pages/incomes/components/ControlSidebarComponent";
import ControlOrderSidebar from "../../pages/orders/components/ControlSidebarComponent";

export default {
  name: "ControlSideBar",
  data() {
    return {
      width: 350,
    };
  },
  created() {
    EventBus.listen(
      EXPENSE_CREATE_CONTROL_SIDEBAR_OPEN,
      ({ accountByLine, taxonomiesByLine }) => {
        const controlSidebar = this.buildConstructor(ControlExpenseSidebar);

        let sidebar = new controlSidebar({
          propsData: {
            accountByLine,
            taxonomiesByLine,
          },
        }).$mount();
        this.buildPanel(sidebar);
      }
    );
    EventBus.listen(INCOME_CREATE_CONTROL_SIDEBAR_OPEN, ({ accountByLine }) => {
      const controlSidebar = this.buildConstructor(ControlIncomeSidebar);

      let sidebar = new controlSidebar({
        propsData: {
          accountByLine,
        },
      }).$mount();
      this.buildPanel(sidebar);
    });
    EventBus.listen(ORDER_CREATE_CONTROL_SIDEBAR_OPEN, ({ foremanByLine }) => {
      const controlSidebar = this.buildConstructor(ControlOrderSidebar);

      let sidebar = new controlSidebar({
        propsData: {
          foremanByLine,
        },
      }).$mount();
      this.buildPanel(sidebar);
    });
  },
  beforeDestroy() {
    EventBus.off([
      EXPENSE_CREATE_CONTROL_SIDEBAR_OPEN,
      INCOME_CREATE_CONTROL_SIDEBAR_OPEN,
    ]);
  },
  methods: {
    /**
     * @link https://github.com/kazupon/vue-i18n/pull/420
     */
    buildConstructor(component) {
      const constructor = Vue.extend({ i18n: this.$i18n });
      return constructor.extend(component);
    },
    buildPanel(panel) {
      this.$refs.sidebarpanel.innerHTML = "";
      this.$refs.sidebarpanel.appendChild(panel.$el);
    },
  },
};
</script>

<style scoped>
</style>

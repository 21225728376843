<template>
  <select-field
    :title="title"
    :form="form"
    :name="name"
    :multiple="multiple"
    label="name"
    :options="options"
    v-bind="$attrs"
    v-on="$listeners"
    @search="onSearch"
  >
    <template v-slot:no-options="{ searching }">
      <template v-if="searching">{{
        $t("pages.projects.no_options_founded")
      }}</template>
      <em style="opacity: 0.5" v-else>{{
        $t("pages.projects.project_search")
      }}</em>
    </template>
    <template #option="{ id, name }">
      <div style="margin: 0">[{{ id }}] {{ name }}</div>
    </template>
  </select-field>
</template>

<script>
import { SelectField, BaseListField } from "../../../components/form";

import {
  DataSearching,
  DataFiltering,
  GetDataRequest,
} from "../../../api/request";
import api from "../../../api/projects";
import { arrayWrap } from "../../../utils/functions";

export default {
  extends: BaseListField,
  props: {
    title: {
      type: [String],
      default() {
        return this.multiple
          ? this.$t("pages.projects.titles")
          : this.$t("pages.projects.title");
      },
    },
  },
  components: {
    SelectField,
  },
  data() {
    return {
      loadOptionsAjax: (searchingText) => {
        const request = new GetDataRequest({
          searching: [
            new DataSearching("projects.name", searchingText, "ILIKE"),
            new DataSearching("projects.id", searchingText, "ILIKE"),
          ],
        });
        return api.getProjects(this.currentWorkShopId, {
          ...request,
        });
      },
      loadOptionsByIds: (ids) => {
        let filteredIds = arrayWrap(ids);

        const request = new GetDataRequest({
          filtering: [
            new DataFiltering("projects.id", filteredIds.join(","), "in"),
          ],
          perPage: filteredIds.length,
        });
        return api.getProjects(this.currentWorkShopId, {
          ...request,
        });
      },
      loadOptions: async () => {
        let { data } = await api.getProjects(this.currentWorkShopId, {
          perPage: 1000,
        });
        this.options = data.data;
      },
    };
  },
};
</script>
<template>
  <text-field :title="$t('pages.expenses.discount')" v-bind="$attrs" v-on="$listeners"></text-field>
</template>

<script>
import { TextField } from "../../../components/form";

export default {
  components: {
    TextField
  }
};
</script>
import axios from "axios";

export default {
    getForemen(work_shop_id, { current_page = 1, per_page = 20, data = {} }) {
        return axios.get(
            `/api/${work_shop_id}/foremen/${current_page}/${per_page}`,
            {
                params: { ...data }
            }
        );
    },

    getForeman(work_shop_id, { id }) {
        return axios.get(`/api/${work_shop_id}/foremen/${id}`);
    },

    getForemenStatuses(work_shop_id) {
        return axios.get(`/api/${work_shop_id}/foremen/statuses`);
    },

    getForemanServicesRelatedWith(
        work_shop_id,
        { id, current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/foremen/services/related-with/${id}/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },

    getForemanServicesNotRelatedWith(work_shop_id, { id, data = {} }) {
        return axios.get(
            `/api/${work_shop_id}/foremen/services/not-related-with/${id}`,
            {
                params: { ...data }
            }
        );
    },

    deleteForemanService(work_shop_id, { id, services }) {
        return axios.delete(`/api/${work_shop_id}/foremen/services/${id}`, {
            params: { services }
        });
    },

    deleteForeman(work_shop_id, { id }) {
        return axios.delete(`/api/${work_shop_id}/foremen/${id}`);
    },

    getMyServices(
        work_shop_id,
        { current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/foremen/my-services/related/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },

    getMyNotNotRelatedServices(work_shop_id, { data = {} }) {
        return axios.get(
            `/api/${work_shop_id}/foremen/my-services/not-related`,
            {
                params: { ...data }
            }
        );
    },

    deleteMyServices(work_shop_id, { services }) {
        return axios.delete(`/api/${work_shop_id}/foremen/my-services`, {
            params: { services }
        });
    }
};

import axios from "axios";

export default {
    getCurrencyExchanges(base) {
        return axios.get(`/api/currency-exchanges/${base}`);
    },

    getCurrencyExchangeByDate(base, searchDate) {
        return axios.get(`/api/currency-exchanges/${base}/${searchDate}`);
    }
};

import Form from "../../../../../components/form/Form";
import { isset, parseDate } from "../../../../../utils/functions";
import {
    TaskStatus,
    TaskPriority,
    TaskType,
    ProjectStage,
    TimerStart,
    TaskPomodoroTime,
    ProjectTaskMember,
} from "../index";

//observer

export default class Task extends Form {
    parse(data) {
        this.id = data.id;
        this.hash = data.hash;
        this.name = data.name;
        this.owner_id = parseInt(data.owner_id);
        this.closed = parseInt(data.closed);
        this.task_priority = new TaskPriority().parse(data.task_priority);
        this.task_type = new TaskType().parse(data.task_type);
        this.task_status = new TaskStatus().parse(data.task_status);
        this.description = data.description;
        this.real_time = data.real_time;
        this.sorting = data.sorting;
        this.planing_time = data.planing_time;
        this.project = data.project.name;
        this.count_finished_sub_tasks = data.count_finished_sub_tasks;
        this.count_all_sub_tasks = data.count_all_sub_tasks;
        this.finished_percent = data.finished_percent;
        this.members = data.members.map((x) =>
            new ProjectTaskMember().parse(x)
        );
        this.owner = this.members.find((x) => x.isOwner);
        this.executor = this.members.find((x) => x.isExecutor);
        this.observers = this.members.filter((x) => x.isFollower);
        this.responsibles = this.members.filter((x) => x.isResponsible);

        this.project_id = data.project.id;
        this.project_name = data.project.name;

        this.project_stage = isset(data.project_stage)
            ? new ProjectStage().parse(data.project_stage)
            : null;

        this.planing_date_start = isset(data.planing_date_start)
            ? parseDate(data.planing_date_start)
            : null;
        this.planing_date_end = isset(data.planing_date_end)
            ? parseDate(data.planing_date_end)
            : null;
        this.can_paused = parseInt(data.can_paused);
        this.can_continue = parseInt(data.can_continue);
        this.can_start = parseInt(data.can_start);
        this.is_working = parseInt(data.is_working);

        this.time_start = isset(data.time_start)
            ? new TimerStart().parse(data.time_start)
            : null;

        this.time_now = parseDate(data.time_now);
        this.initial_at = parseDate(data.initial_at);
        this.finished_at = parseDate(data.finished_at);

        this.is_default =
            parseInt(data.task_status.is_default) === 1 ? true : false;

        this.created_at = parseDate(data.created_at);

        this.pomo_duration = parseInt(data.pomo_duration);
        this.pomodoro = new TaskPomodoroTime().parse(data.pomodoro);

        return this;
    }

    get isClosed() {
        return this.closed === 1;
    }

    get canStart() {
        return this.can_start === 1;
    }

    get canPaused() {
        return this.can_paused === 1;
    }

    get canContinue() {
        return this.can_continue === 1;
    }

    get isWorking() {
        return this.is_working === 1;
    }
}

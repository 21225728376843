<template>
  <toogle-field
    :title="$t('pages.accounts.is_active')"
    v-bind="$attrs"
    v-on="$listeners"
    :required="true"
  ></toogle-field>
</template>

<script>
import { ToogleField } from "../../../components/form";

export default {
  components: {
    ToogleField,
  },
};
</script>
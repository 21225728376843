<template>
  <select-field
    :title="title"
    :form="form"
    :name="name"
    :multiple="multiple"
    label="name"
    :options="options"
    v-bind="$attrs"
    v-on="$listeners"
    @search="onSearch"
    :required="required"
  ></select-field>
</template>

<script>
import { SelectField, BaseListField } from "../../../components/form";

import {
  DataSearching,
  DataFiltering,
  GetDataRequest,
} from "../../../api/request";
import api from "../../../api/currencies";

import { arrayWrap } from "../../../utils/functions";

export default {
  extends: BaseListField,
  components: {
    SelectField,
  },
  data() {
    return {
      loadOptionsAjax: (searchingText) => {
        const request = new GetDataRequest({
          searching: [
            new DataSearching("currencies.name", searchingText, "ILIKE"),
            new DataSearching("currencies.id", searchingText, "ILIKE"),
          ],
        });
        return api.getCurrencies({
          ...request,
        });
      },
      loadOptionsByIds: (ids) => {
        let filteredIds = arrayWrap(ids);

        const request = new GetDataRequest({
          filtering: [
            new DataFiltering("currencies.id", filteredIds.join(","), "in"),
          ],
          perPage: filteredIds.length,
        });
        return api.getCurrencies({
          ...request,
        });
      },
      loadOptions: async () => {
        let { data } = await api.getCurrencies({
          perPage: 1000,
        });
        this.options = data.data;
      },
    };
  },
  computed: {
    title() {
      return this.multiple
        ? this.$t("pages.currencies.titles")
        : this.$t("pages.currencies.title");
    },
  },
};
</script>

<style>
</style>
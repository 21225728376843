var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select-field", {
    attrs: {
      title: _vm.$t("pages.orders.address"),
      id: _vm.id,
      form: _vm.form,
      name: _vm.name,
      label: "address",
      options: _vm.options,
      required: _vm.required,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "no-options",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "m-1", staticStyle: { opacity: "0.5" } },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("pages.clients.address.no_options_founded")
                      ) +
                      "\n    "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
        _vm.showCreationButton && _vm.hasPermission("create_client_address")
          ? {
              key: "list-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "text-center border-footer-field p-2",
                      on: { click: _vm.creationButtonClicked },
                    },
                    [
                      _c("i", { staticClass: "fas fa-plus text-success" }),
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("pages.clients.address.add")) +
                          "\n    "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
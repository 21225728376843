var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select-field",
    _vm._g(
      _vm._b(
        {
          attrs: {
            title: _vm.title,
            form: _vm.form,
            name: _vm.name,
            multiple: _vm.multiple,
            label: "id",
            options: _vm.options,
          },
          on: { search: _vm.onSearch },
          scopedSlots: _vm._u([
            {
              key: "no-options",
              fn: function (ref) {
                var searching = ref.searching
                return [
                  searching
                    ? [
                        _vm._v(
                          _vm._s(_vm.$t("pages.expenses.no_options_founded"))
                        ),
                      ]
                    : _c("em", { staticStyle: { opacity: "0.5" } }, [
                        _vm._v(_vm._s(_vm.$t("pages.expenses.expense_search"))),
                      ]),
                ]
              },
            },
            {
              key: "option",
              fn: function (ref) {
                var id = ref.id
                var refundType = ref.refundType
                var refundTotal = ref.refundTotal
                var refundIncomeTotal = ref.refundIncomeTotal
                var currency = ref.currency
                var refundBalance = ref.refundBalance
                return [
                  _c("div", { staticStyle: { margin: "0" } }, [
                    _vm._v(
                      _vm._s(_vm.$t("pages.expenses.id")) + ": #" + _vm._s(id)
                    ),
                  ]),
                  _vm._v(" "),
                  refundType.id != 0
                    ? _c(
                        "div",
                        [
                          _c("span", [_vm._v(_vm._s(refundType.name))]),
                          _vm._v(" "),
                          _c("my-vue-progress-bar", {
                            attrs: {
                              "max-value": refundTotal,
                              "current-value": refundIncomeTotal,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "description",
                                  fn: function () {
                                    return [
                                      _c(
                                        "small",
                                        { staticClass: "badge bg-success" },
                                        [
                                          _c("formatted-number-field", {
                                            attrs: {
                                              val: refundIncomeTotal,
                                              money: true,
                                              "default-currency": currency,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        {
                                          staticClass: "badge",
                                          staticStyle: {
                                            "background-color": "#bcbfc5",
                                          },
                                        },
                                        [
                                          _c("formatted-number-field", {
                                            attrs: {
                                              val: refundBalance,
                                              money: true,
                                              "default-currency": currency,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        "select-field",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default class ProjectMember {
    parse(data) {    
        this.id = parseInt(data.id);
        this.name = data.name;
        this.avatar = data.avatar;
        this.role_name = data.role_name;
        
        return this;
    }
}

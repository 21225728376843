var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("nav-bar"),
      _vm._v(" "),
      _c("side-bar"),
      _vm._v(" "),
      _c("child"),
      _vm._v(" "),
      _c("footer-bar"),
      _vm._v(" "),
      _c("control-side-bar"),
      _vm._v(" "),
      _c("receipt-create-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    class="modal fade"
    :id="id"
    data-keyboard="false"
    data-backdrop="static"
    :style="{ 'z-index': zindex }"
  >
    <div
      class="modal-dialog"
      :class="{
        'modal-dialog-scrollable': modalDialogScrollable,
        'modal-lg': modalLarge,
      }"
    >
      <div
        class="modal-content"
        :class="{ 'bg-danger': modalBgDanger, 'bg-warning': modalBgWarning }"
        @keydown.enter="saveOnEnterHandler"
      >
        <div
          class="overlay d-flex justify-content-center align-items-center"
          v-if="showProgress"
        >
          <i class="fas fa-2x fa-sync fa-spin"></i>
        </div>
        <div class="modal-header">
          <h4 class="modal-title">{{ modalTitle }}</h4>
          <button
            type="button"
            class="close"
            @click="closeButtonClicked"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div
          class="modal-footer"
          v-if="showSaveFooterButton || showCloseFooterButton"
        >
          <button
            type="button"
            class="btn"
            :class="{ 'btn-secondary': !modalBgWarning && !modalBgDanger }"
            @click="closeButtonClicked"
            v-if="showCloseFooterButton"
          >
            {{ closeButtonTranslation }}
          </button>

          <button
            type="button"
            class="btn"
            :class="{
              'btn-primary': !modalBgWarning && !modalBgDanger,
              'btn-outline-light': modalBgWarning || modalBgDanger,
            }"
            @click.prevent="saveButtonClicked"
            v-if="showSaveFooterButton"
          >
            {{ saveButtonTranslation }}
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import * as events from "./modal-events";

/**
 * Generate events
 * SAVE_BUTTON_CLICKED
 * CLOSE_BUTTON_CLICKED
 */
export default {
  name: "VueMyModal",
  props: {
    modalDialogScrollable: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: "",
    },
    showCloseFooterButton: {
      type: Boolean,
      default: true,
    },
    showSaveFooterButton: {
      type: Boolean,
      default: true,
    },
    saveFooterButtonTranslation: {
      type: String,
      default: null,
    },
    closeFooterButtonTranslation: {
      type: String,
      default: null,
    },
    modalLarge: {
      type: Boolean,
      default: true,
    },
    modalBgWarning: {
      type: Boolean,
      default: false,
    },
    modalBgDanger: {
      type: Boolean,
      default: false,
    },
    saveOnEnter: {
      type: Boolean,
      default: true,
    },
    zindex: {
      type: Number,
      default: 1060,
    },
  },
  data() {
    return {
      // eslint-disable-next-line no-undef
      id: _.uniqueId("modal_"),
    };
  },
  watch: {
    showModal: {
      handler(val) {
        val ? this.show() : this.hide();
      },
      immediate: true,
    },
  },
  computed: {
    closeButtonTranslation() {
      return this.$isset(this.closeFooterButtonTranslation)
        ? this.closeFooterButtonTranslation
        : this.$t("actions.close");
    },
    saveButtonTranslation() {
      return this.$isset(this.saveFooterButtonTranslation)
        ? this.saveFooterButtonTranslation
        : this.$t("actions.save");
    },
  },
  mounted() {
    $(`#${this.id}`).on("shown.bs.modal", function () {
      $(this).find("[autofocus]").focus();
    });
  },
  methods: {
    saveOnEnterHandler() {
      //when focused on texarea, select o tiptap, not cacth event
      const activeElement = document.activeElement;
      if (
        this.saveOnEnter &&
        document.activeElement.className !== "vs__search" && //vue-select
        document.activeElement.className !== "vue-treeselect__input" && //vue-tree-select
        document.activeElement.className !== "menububble__input" && //tiptap
        !["div", "textarea", "body", "select"].includes(
          activeElement.tagName.toLowerCase()
        )
      ) {
        this.saveButtonClicked();
      }
    },
    saveButtonClicked() {
      this.$emit(events.SAVE_BUTTON_CLICKED);
    },
    closeButtonClicked() {
      this.$emit(events.CLOSE_BUTTON_CLICKED);
    },
    show() {
      // eslint-disable-next-line no-undef
      $(`#${this.id}`).modal("show");
      // .on("shown.bs.modal", function () {
      //   $("input_element_id").focus();
      // });
    },
    hide() {
      // eslint-disable-next-line no-undef
      $(`#${this.id}`).modal("hide");
    },
  },
};
</script>

<style>
</style>

<template>
  <treeselect
    :options="options"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="year"
    :placeholder="$t('datetime.year')"
  />
</template>

<script>
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapGetters } from "vuex";

export default {
  components: {
    Treeselect,
  },
  computed: {
    ...mapGetters("lang", ["locale"]),
    options() {
      let options = [];
      const currentYear = new Date().getFullYear();

      for (let i = 0; i < 10; i++) {
        let year = currentYear - i;
        options.push({
          id: year,
          label: year,
        });
      }
      return options;
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  watch: {
    year: {
      handler(val) {
        this.$emit("changed-year", val);
      },
      immediate: true,
    },
  },
};
</script>
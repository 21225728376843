var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "text-field",
    _vm._g(
      _vm._b(
        { attrs: { title: _vm.$t("pages.accounts.name") } },
        "text-field",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
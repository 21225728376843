import { isset, parseDate } from "../../../utils/functions";
import {
    EXPENSE_ARTICLE_LINE,
    EXPENSE_SERVICE_LINE,
    DISCOUNT_TYPE_FIX_SUM,
    DISCOUNT_TYPE_FIX_SUM_BY_ONE,
    DISCOUNT_TYPE_PERCENT,
} from "../../constants";

import Form from "../../../components/form";
import Vue from "vue";

export class Expense extends Form {
    constructor() {
        super();

        this.id = null;
        this.expenseType = null;
        this.dateExpense = null;
        this.createdAt = null;
        this.project = null;
        this.account = null;
        this.client = null;
        this.notes = null;
        this.internalNotes = null;
        this.currency = null;
        this.toCurrency = null;
        this.taxonomies = [];
        this.refundType = null;
        this.refundValue = null;
        this.refundTotal = 0;
        this.refundIncomeTotal = 0;
        this.refundBalance = 0;

        this.rate = null;
        this.dateRate = null;

        this.discountByLine = null;
        this.accountByLine = false;
        this.taxonomiesByLine = false;

        this.total = null;
        this.totalDiscount = null;
        this.totalWithDiscount = null;

        this.totalToCurrency = null;
        this.totalDiscountToCurrency = null;
        this.totalWithDiscountToCurrency = null;

        this._expenseLines = [];
        this.resetSummary();
    }

    parse(data, calculateSummary = true) {
        this.id = data.id;
        this.createdAt = null;
        this.expenseType = data.expenseType;
        this.dateExpense = parseDate(data.dateExpense);
        this.accountByLine = data.accountByLine === 1 ? true : false;
        this.taxonomiesByLine = data.taxonomiesByLine === 1 ? true : false;
        this.discountByLine = data.discountByLine;
        this.taxonomies = isset(data.taxonomies) ? data.taxonomies : [];
        this.refundType = data.refundType;
        this.refundValue = data.refundValue;
        this.refundTotal = data.refundTotal;
        this.refundIncomeTotal = data.refundIncomeTotal;
        this.refundBalance = data.refundBalance ?? 0;

        this.rate = data.rate;
        this.dateRate = isset(data.dateRate) ? parseDate(data.dateRate) : null;
        this.toCurrency = isset(data.toCurrency) ? data.toCurrency : null;

        this.notes = isset(data.notes) ? data.notes : null;
        this.internalNotes = isset(data.internalNotes)
            ? data.internalNotes
            : null;

        this.client = isset(data.client)
            ? new Client({
                  id: data.client.id,
                  name: data.client.name,
              })
            : null;

        this.account = isset(data.account)
            ? new Account({
                  id: data.account.id,
                  name: data.account.name,
              })
            : null;

        this.project = isset(data.project)
            ? new Project({
                  id: data.project.id,
                  name: data.project.name,
              })
            : null;

        this.currency = data.currency;
        this.expenseLines = isset(data.expenseLines) ? data.expenseLines : [];

        this.WorkShopId = isset(data.work_shop_id) ? data.work_shop_id : null;
        this.total = isset(data.total) ? data.total : null;
        this.totalDiscount = isset(data.totalDiscount)
            ? data.totalDiscount
            : null;
        this.totalWithDiscount = isset(data.totalWithDiscount)
            ? data.totalWithDiscount
            : null;

        this.totalToCurrency = isset(data.totalToCurrency)
            ? data.totalToCurrency
            : null;
        this.totalDiscountToCurrency = isset(data.totalDiscountToCurrency)
            ? data.totalDiscountToCurrency
            : null;
        this.totalWithDiscountToCurrency = isset(
            data.totalWithDiscountToCurrency
        )
            ? data.totalWithDiscountToCurrency
            : null;

        if (calculateSummary) {
            this.summary();
        }

        return this;
    }

    get expenseLines() {
        return this._expenseLines;
    }

    set expenseLines(lines) {
        this._expenseLines = [];
        for (let line of lines) {
            let expenseLine = null;
            switch (line.typeLine) {
                case EXPENSE_ARTICLE_LINE:
                    expenseLine = new ArticleLine({
                        id: line.id,
                        typeLine: line.typeLine,
                        article: line.serviceArticle,
                        notes: line.notes,
                        account: isset(line.account)
                            ? new Account({
                                  id: line.account.id,
                                  name: line.account.name,
                              })
                            : null,
                        price: line.price,
                        quantity: line.quantity,
                        discount: line.discount,
                        discountType: line.discountType,
                        taxonomies: line.taxonomies,
                        total: line.total,
                        totalDiscount: line.totalDiscount,
                        totalWithDiscount: line.totalWithDiscount,
                    });
                    break;
                case EXPENSE_SERVICE_LINE:
                    expenseLine = new ServiceLine({
                        id: line.id,
                        typeLine: line.typeLine,
                        service: line.serviceArticle,
                        notes: line.notes,
                        account: isset(line.account)
                            ? new Account({
                                  id: line.account.id,
                                  name: line.account.name,
                              })
                            : null,
                        price: line.price,
                        quantity: line.quantity,
                        discount: line.discount,
                        discountType: line.discountType,
                        taxonomies: line.taxonomies,
                        total: line.total,
                        totalDiscount: line.totalDiscount,
                        totalWithDiscount: line.totalWithDiscount,
                    });
                    break;
                default:
                    expenseLine = new FreeLine({
                        id: line.id,
                        typeLine: line.typeLine,
                        name: line.name,
                        notes: line.notes,
                        account: isset(line.account)
                            ? new Account({
                                  id: line.account.id,
                                  name: line.account.name,
                              })
                            : null,
                        price: line.price,
                        quantity: line.quantity,
                        discount: line.discount,
                        discountType: line.discountType,
                        taxonomies: line.taxonomies,
                        total: line.total,
                        totalDiscount: line.totalDiscount,
                        totalWithDiscount: line.totalWithDiscount,
                    });
            }
            this._expenseLines.push(expenseLine);
        }
    }

    updateOrCreateLine(line) {
        const discount = line.discount > 0 ? line.discount : 0;
        let index = _.findIndex(this.expenseLines, (row) => {
            return parseInt(row.id) === parseInt(line.id);
        });
        const total = line.price * line.quantity;
        const totalDiscount = this.calcDiscount(
            line.quantity,
            line.discountType,
            total,
            discount
        );
        const totalWithDiscount = total - totalDiscount;

        let expenseLine = null;
        switch (line.typeLine) {
            case EXPENSE_ARTICLE_LINE:
                expenseLine = new ArticleLine({
                    id: line.id,
                    typeLine: line.typeLine,
                    article: line.serviceArticle,
                    account: isset(line.account)
                        ? new Account({
                              id: line.account.id,
                              name: line.account.name,
                          })
                        : null,
                    notes: line.notes,
                    price: line.price,
                    quantity: line.quantity,
                    discount: line.discount,
                    discountType: line.discountType,
                    taxonomies: line.taxonomies,
                    total: total,
                    totalDiscount: totalDiscount,
                    totalWithDiscount: totalWithDiscount,
                });
                break;
            case EXPENSE_SERVICE_LINE:
                expenseLine = new ServiceLine({
                    id: line.id,
                    typeLine: line.typeLine,
                    service: line.serviceArticle,
                    account: isset(line.account)
                        ? new Account({
                              id: line.account.id,
                              name: line.account.name,
                          })
                        : null,
                    notes: line.notes,
                    price: line.price,
                    quantity: line.quantity,
                    discount: line.discount,
                    discountType: line.discountType,
                    taxonomies: line.taxonomies,
                    total: total,
                    totalDiscount: totalDiscount,
                    totalWithDiscount: totalWithDiscount,
                });
                break;
            default:
                expenseLine = new FreeLine({
                    id: line.id,
                    typeLine: line.typeLine,
                    account: isset(line.account)
                        ? new Account({
                              id: line.account.id,
                              name: line.account.name,
                          })
                        : null,
                    name: line.name,
                    notes: line.notes,
                    price: line.price,
                    quantity: line.quantity,
                    discount: line.discount,
                    discountType: line.discountType,
                    taxonomies: line.taxonomies,
                    total: total,
                    totalDiscount: totalDiscount,
                    totalWithDiscount: totalWithDiscount,
                });
        }

        //update line
        if (index !== -1) {
            Vue.set(this.expenseLines, index, expenseLine);
        } else {
            this._expenseLines.push(expenseLine);
        }
        this.summary();
    }

    calcDiscount(quantity, discount_type, total, discount) {
        if (!isset(discount_type)) return 0;

        switch (discount_type.id) {
            case DISCOUNT_TYPE_FIX_SUM:
                return discount;
            case DISCOUNT_TYPE_FIX_SUM_BY_ONE:
                return discount * quantity;
            case DISCOUNT_TYPE_PERCENT:
                return (total * discount) / 100;
            default:
                return 0;
        }
    }

    removeExpenseLine(id) {
        let index = this.expenseLines.findIndex(
            (line) => parseInt(line.id) === parseInt(id)
        );
        if (index !== -1) {
            Vue.delete(this.expenseLines, index);
            this.summary();
            return true;
        }
        return false;
    }

    resetSummary() {
        this.total = 0;
        this.totalDiscount = 0;
        this.totalWithDiscount = 0;
        this.totalToCurrency = 0;
        this.totalDiscountToCurrency = 0;
        this.totalWithDiscountToCurrency = 0;
    }

    summary() {
        if (this.expenseLines.length > 0) {
            this.resetSummary();
            for (let line of this.expenseLines) {
                this.total += parseFloat(line.total);
                this.totalDiscount += parseFloat(line.totalDiscount);
                this.totalWithDiscount += parseFloat(line.totalWithDiscount);
            }
            this.summaryToCurrency();
        }
    }

    summaryToCurrency() {
        this.totalToCurrency = this.total * this.rate;
        this.totalDiscountToCurrency = this.totalDiscount * this.rate;
        this.totalWithDiscountToCurrency = this.totalWithDiscount * this.rate;
    }

    data() {
        let data = super.data();

        let request = {
            expenseType: data.expenseType,
            client: data.client,
            refundType: data.refundType,
            refundValue: data.refundValue,
            account: data.account,
            project: data.project,
            dateExpense: data.dateExpense,
            taxonomies: data.taxonomies,
            notes: data.notes,
            rate: data.rate,
            dateRate: data.dateRate,
            internalNotes: data.internalNotes,
            currency: data.currency,
            taxonomiesByLine: data.taxonomiesByLine ? 1 : 0,
            accountByLine: data.accountByLine ? 1 : 0,
            expenseLines: this.expenseLines.map((row) => {
                let line = {};

                line.typeLine = row.typeLine;
                line.price = row.price;
                line.quantity = row.quantity;
                line.notes = row.notes;
                line.discount = row.discount;
                line.discountType = row.discountType?.id;

                if (isset(row.account)) {
                    line.account = row.account.id;
                }

                if (isset(row.taxonomies)) {
                    line.taxonomies = row.taxonomies;
                }

                switch (line.typeLine) {
                    case EXPENSE_ARTICLE_LINE:
                        line.serviceArticle = row.article.id;
                        break;
                    case EXPENSE_SERVICE_LINE:
                        line.serviceArticle = row.service.id;
                        break;
                    default:
                        line.name = row.name;
                }

                return line;
            }),
        };

        return request;
    }
}

export class Account {
    /**
     *
     * @param {object} data - account data
     * @param {Number} data.id
     * @param {String} data.name
     */
    constructor({ id, name }) {
        this.id = id;
        this.name = name;
    }
}

export class Project {
    /**
     *
     * @param {object} data - project data
     * @param {Number} data.id
     * @param {String} data.name
     */
    constructor({ id, name }) {
        this.id = id;
        this.name = name;
    }
}

export class Client {
    /**
     *
     * @param {object} data - client data
     * @param {Number} data.id
     * @param {String} data.name
     */
    constructor({ id, name }) {
        this.id = id;
        this.name = name;
    }
}

export class Article {
    /**
     *
     * @param {object} data - article
     * @param {Number} data.id
     * @param {String} data.name
     * @param {String} data.code_article
     * @param {String} data.description
     * @param {Number} data.price
     * @param {Number} data.is_active
     *
     */
    constructor({
        id,
        name,
        code_article,
        description,
        price,
        is_active,
        tag,
    }) {
        this.id = id;
        this.name = name;
        this.code_article = code_article;
        this.description = description;
        this.price = price;
        this.is_active = is_active;
        this.tag = tag;
    }
}

export class Service {
    /**
     *
     * @param {object} data service
     * @param {Number} data.id
     * @param {String} data.name
     * @param {String} data.notes
     * @param {String} data.price
     * @param {Number} data.is_active
     *
     */
    constructor({ id, name, notes, price, is_active, tag }) {
        this.id = id;
        this.name = name;
        this.notes = notes;
        this.price = price;
        this.is_active = is_active;
        this.tag = tag;
    }
}

export class ArticleLine {
    constructor({
        id,
        article,
        account,
        typeLine,
        notes,
        price,
        quantity,
        discount,
        discountType,
        total,
        totalDiscount,
        totalWithDiscount,
        taxonomies,
    }) {
        this.id = id;
        this.typeLine = typeLine;
        this.article = new Article(article);
        this.account = account;
        this.name = this.article.name;
        this.notes = notes;
        this.price = price;
        this.quantity = quantity;
        this.discount = discount;
        this.discountType = discountType;
        this.total = total;
        this.totalDiscount = totalDiscount;
        this.totalWithDiscount = totalWithDiscount;
        this.taxonomies = taxonomies;
    }
}

export class ServiceLine {
    constructor({
        id,
        service,
        account,
        typeLine,
        notes,
        price,
        quantity,
        discount,
        discountType,
        total,
        totalDiscount,
        totalWithDiscount,
        taxonomies,
    }) {
        this.id = id;
        this.typeLine = typeLine;
        this.service = new Service(service);
        this.account = account;
        this.name = this.service.name;
        this.notes = notes;
        this.price = price;
        this.quantity = quantity;
        this.discount = discount;
        this.discountType = discountType;
        this.total = total;
        this.totalDiscount = totalDiscount;
        this.totalWithDiscount = totalWithDiscount;
        this.taxonomies = taxonomies;
    }
}

export class FreeLine {
    constructor({
        id,
        name,
        account,
        typeLine,
        notes,
        price,
        quantity,
        discount,
        discountType,
        total,
        totalDiscount,
        totalWithDiscount,
        taxonomies,
    }) {
        this.id = id;
        this.typeLine = typeLine;
        this.account = account;
        this.name = name;
        this.notes = notes;
        this.price = price;
        this.quantity = quantity;
        this.discount = discount;
        this.discountType = discountType;
        this.total = total;
        this.totalDiscount = totalDiscount;
        this.totalWithDiscount = totalWithDiscount;
        this.taxonomies = taxonomies;
    }
}

// Receipt
export class Receipt extends Form {
    constructor() {
        super();

        this.id = null;
        this.dateExpense = null;
        this.createdAt = null;
        this.project = null;
        this.account = null;
        this.client = null;
        this.currency = null;
        this.toCurrency = null;
        this.taxonomies = [];
        this.refundType = null;
        this.refundValue = null;

        this.rate = null;
        this.name = null;
        this.price = null;
        this.quantity = 1;
        this.discount = null;
        this.discountType = null;
    }

    data() {
        let data = super.data();

        let request = {
            client: data.client,
            account: data.account,
            project: data.project,
            dateExpense: data.dateExpense,
            taxonomies: data.taxonomies,
            rate: data.rate,
            dateRate: data.dateExpense,
            currency: data.currency,
            quantity: data.quantity,
            price: data.price,
            name: data.name,
            discount: data.discount,
            discountType: data.discountType,
            refundType: data.refundType,
            refundValue: data.refundValue,
        };

        return request;
    }
}

// Refund
export class Refund extends Form {
    constructor() {
        super();

        this.id = null;
        this.dateRefund = null;
        this.expense = null;
        this.createdAt = null;
        this.project = null;
        this.account = null;
        this.client = null;
        this.currency = null;
        this.toCurrency = null;

        this.rate = null;
        this.name = null;
        this.price = null;
        this.quantity = 1;
        this.discount = null;
    }

    data() {
        let data = super.data();

        let request = {
            client: data.client,
            account: data.account,
            project: data.project,
            expense: data.expense,
            dateRefund: data.dateRefund,
            rate: data.rate,
            dateRate: data.dateRefund,
            currency: data.currency,
            quantity: data.quantity,
            price: data.price,
            name: data.name,
            discount: data.discount,
        };

        return request;
    }
}

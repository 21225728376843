import Form from "../../../../../components/form/Form";
import { isset } from "../../../../../utils/functions";
import SubTaskParent from "./SubTaskParent";

export default class SubTask extends Form {
    parse(data) {
        this.id = data.id;
        this.description = data.description;
        this.is_finished = parseInt(data.is_finished) === 1 ? true : false;
        this.is_paused = data.is_paused;
        this.is_working = data.is_working;
        this.name = data.name;
        this.sorting = data.sorting;
        this.pinned_id = data.pinned_id;

        this.sub_task_parent = isset(data.sub_task_parent)
            ? new SubTaskParent().parse(data.sub_task_parent)
            : null;

        return this;
    }

    get isPinned() {
        return isset(this.pinned_id);
    }

    get isWorking() {
        return this.is_working === 1;
    }
}

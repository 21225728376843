import axios from "axios";

export default {
    getTaxonomies(
        work_shop_id,
        { current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/taxonomies/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    }
};

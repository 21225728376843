<template>
  <select-field
    :title="$t('pages.taxonomies.titles')"
    :form="form"
    :name="name"
    :multiple="multiple"
    label="name"
    :options="options"
    v-bind="$attrs"
    v-on="$listeners"
    @search="onSearch"
    taggable
  >
    <template v-slot:no-options="{ search, searching }">
      <template v-if="searching">{{
        $t("pages.taxonomies.no_options_founded")
      }}</template>
      <em style="opacity: 0.5" v-else>{{
        $t("pages.taxonomies.taxonomy_search")
      }}</em>
    </template>
    <template #option="{ id, name }">
      <div style="margin: 0">[{{ id }}] {{ name }}</div>
    </template>
    <template #option="{ id, name }">
      <span>{{ name }}</span>
    </template>
  </select-field>
</template>

<script>
import { SelectField, BaseListField } from "../../../components/form";

import {
  DataSearching,
  DataFiltering,
  GetDataRequest,
} from "../../../api/request";
import api from "../../../api/taxonomies";
import { arrayWrap } from "../../../utils/functions";

export default {
  extends: BaseListField,
  props: {
    taxonomyType: {
      type: [String, Number],
      required: true,
    },
    taggable: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    SelectField,
  },
  data() {
    return {
      loadOptionsAjax: async (searchingText) => {
        console.log("field1");
        const request = new GetDataRequest({
          searching: [new DataSearching("taxonomies.name", searchingText)],
          filtering: [
            new DataFiltering(
              "taxonomies.taxonomy_type",
              this.taxonomyType,
              "="
            ),
          ],
        });

        const { data } = await api.getTaxonomies(this.currentWorkShopId, {
          ...request,
        });

        return new Promise((resolve) => {
          let tags = data.data.map((x) => x.name);
          console.log(tags);
          resolve({ data: tags });
        });
      },
      loadOptionsByIds: async (ids) => {
        let filteredIds = arrayWrap(ids);

        const request = new GetDataRequest({
          filtering: [
            new DataFiltering("projects.id", filteredIds.join(","), "in"),
          ],
          perPage: filteredIds.length,
        });
        const { data } = await api.getTaxonomies(this.currentWorkShopId, {
          ...request,
        });

        return new Promise((resolve) => {
          let tags = data.data.map((x) => x.name);
          console.log(tags);
          resolve({ data: tags });
        });
      },
      loadOptions: async () => {
        const request = new GetDataRequest({
          filtering: [
            new DataFiltering(
              "taxonomies.taxonomy_type",
              this.taxonomyType,
              "="
            ),
          ],
        });
        let { data } = await api.getTaxonomies(this.currentWorkShopId, {
          perPage: 1000,
          ...request,
        });
        this.options = data.data.map((x) => x.name);
      },
    };
  },
};
</script>
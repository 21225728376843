import Cookies from 'js-cookie'
import { TOGGLE_ACCOUNT_TAG } from '../mutation-types'

// state
export const state = {
    accountTag: Cookies.get('accountTag') !== null && Cookies.get('accountTag') !== undefined ? JSON.parse(Cookies.get('accountTag')) : [],
}

// getters
export const getters = {
    accountTag: state => state.accountTag,
    isShowAccountTag: state => work_shop_id => {
        let account = state.accountTag.find(item => parseInt(item.k) === parseInt(work_shop_id));
        if (account !== undefined) {
            return account.v;
        }
        return true;
    }
}

// mutations
export const mutations = {
    [TOGGLE_ACCOUNT_TAG](state, { work_shop_id, value }) {
        let account = state.accountTag.find(item => parseInt(item.k) === parseInt(work_shop_id));
        if (account !== undefined) {
            account.v = value;
        } else {
            state.accountTag.push({ k: work_shop_id, v: value });
        }
        Cookies.set('accountTag', state.accountTag, { expires: 365 });
    },
}

export const actions = {
    saveToggleTag({ commit }, payload) {
        commit(TOGGLE_ACCOUNT_TAG, payload);
    },

};
import { find, debounce } from "lodash";
import { arrayWrap, isset } from "../../../utils/functions";
import Form from "../index";
import { mapGetters } from "vuex";

export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        form: {
            type: Form,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        ajaxSearching: {
            type: Boolean,
            default: false,
        },
        initWhenCreated: {
            type: Boolean,
            default: true,
        },
        advancedSearch: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        initWithDefault: {
            type: Boolean,
            default: false,
        },
        filterable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters("auth", ["currentWorkShopId"]),
    },
    data() {
        return {
            options: [],
            /**
             * Функция для загрузки данных аяксом
             *
             * @param {String} searchingText
             * @returns
             */
            loadOptionsAjax: (searchingText) => {
                console.error(
                    "repository is not initialized: " + searchingText
                );
                return [];
            },
            /**
             * Функция для загрузки данных по ид. При аяксе чтобы подгрузить выбранное
             * @param {Array.<int>|int} ids
             * @returns
             */
            loadOptionsByIds: (ids) => {
                console.error("loadOptionsByIds is not initialized: " + ids);
                return [];
            },
            /**
             * Использовать когда поле загружает конечный список
             * без аякс поиска
             */
            loadOptions: () => {
                console.error("loadOptions is not initialized: ");
            },
        };
    },
    created() {
        if (this.ajaxSearching === false && this.initWhenCreated) {
            this.loadOptions();
            this._initDefault();
        }
    },
    methods: {
        async setOptionIds(ids) {
            if (isset(ids)) {
                ids = arrayWrap(ids);
                //if fields is loading by ajax
                if (this.ajaxSearching) {
                    let { data } = await this.loadOptionsByIds(ids);
                    var sortedAsIds = [];
                    for (let id of ids) {
                        let option = find(data.data, (item) => item.id === id);
                        sortedAsIds.push(option);
                    }
                    this.options = sortedAsIds;
                    if (sortedAsIds.length > 0) {
                        this.form[this.name] = this.multiple
                            ? this.options
                            : this.options[0];
                    }
                } else if (this.initWhenCreated) {
                    //if list is loaded when created
                    this._sortItems(ids);
                    this._initDefault();
                } else {
                    await this.loadOptions();
                    this._sortItems(ids);
                    this._initDefault();
                }
            } else if (
                this.initWhenCreated === false &&
                this.ajaxSearching === false
            ) {
                //when need load field only when show
                await this.loadOptions();
                this._initDefault();
            }
        },
        onSearch(search, loading) {
            if (this.ajaxSearching && search.length > 0) {
                loading(true);
                this._searchItems(loading, search, this);
            }
        },
        _searchItems: debounce((loading, search, vm) => {
            let searchingText = `%${search}%`;
            if (vm.advancedSearch) {
                searchingText = search;
            }

            vm.loadOptionsAjax(searchingText).then((response) => {
                vm.options = response.data.data;
                loading(false);
            });
        }, 250),
        _sortItems(ids) {
            //if list is loaded when created
            var sortedAsIds = [];
            for (let id of ids) {
                let option = find(this.options, (item) => item.id === id);
                sortedAsIds.push(option);
            }
            if (sortedAsIds.length > 0) {
                this.form[this.name] = this.multiple
                    ? sortedAsIds
                    : sortedAsIds[0];
            }
        },
        _initDefault() {
            if (this.initWithDefault && !this.$isset(this.form[this.name])) {
                this.form[this.name] = this.options.find(
                    (x) => parseInt(x.is_default) === 1
                );
            }
        },
    },
};

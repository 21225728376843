<template>
  <text-area-field
    :title="$t('pages.expenses.notes')"
    :rows="1"
    v-bind="$attrs"
    v-on="$listeners"
  ></text-area-field>
</template>

<script>
import { TextAreaField } from "../../../components/form";

export default {
  components: {
    TextAreaField,
  },
};
</script>
import axios from "axios";
import { GetDataRequest, DataSorting } from "./request";

export default {
    getProjects(work_shop_id, { current_page = 1, per_page = 20, data = {} }) {
        return axios.get(
            `/api/${work_shop_id}/projects/${current_page}/${per_page}`,
            {
                params: { ...data }
            }
        );
    },

    getTaskWorkingOrPaused(
        work_shop_id,
        { current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/projects/task-working-or-paused/${current_page}/${per_page}`,
            {
                params: { ...data }
            }
        );
    },

    getProject(work_shop_id, { id }) {
        return axios.get(`/api/${work_shop_id}/projects/${id}`);
    },

    getTaskTimeSummaryByProject(work_shop_id, project_id) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/task-time-summary-for-period`
        );
    },

    getTaskTimeSummaryForPeriod(
        work_shop_id,
        project_id,
        { date_start = null, date_end = null }
    ) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/task-time-summary-for-period/${date_start}/${date_end}`
        );
    },

    getTaskTimeSummaryByDayForPeriod(
        work_shop_id,
        project_id,
        { date_start = null, date_end = null }
    ) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/task-time-summary-by-day-for-period/${date_start}/${date_end}`
        );
    },

    deleteProject(work_shop_id, { id }) {
        return axios.delete(`/api/${work_shop_id}/projects/${id}`);
    },

    updateProjectSorting(work_shop_id, project_id, to_project_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/sorting`,
            {
                to_project_id
            }
        );
    },

    getProjectStages(
        work_shop_id,
        project_id,
        { current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/stages/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },

    deleteProjectStage(work_shop_id, project_id, { id }) {
        return axios.delete(
            `/api/${work_shop_id}/projects/${project_id}/stages/${id}`
        );
    },

    getProjectStage(work_shop_id, project_id, { id }) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/stages/${id}`
        );
    },

    getStageChildren(work_shop_id, project_id, stage_id) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/stages/children/${stage_id}`
        );
    },

    updateProjectStage(work_shop_id, project_id, { id, data }) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/stages/${id}`,
            {
                ...data
            }
        );
    },

    getTasks(
        work_shop_id,
        project_id,
        { current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/tasks/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },

    getTasksClosedStats(work_shop_id, project_id, { data = {} }) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/tasks/closed-stats`,
            {
                params: { ...data }
            }
        );
    },

    getTask(work_shop_id, project_id, { id }) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/tasks/${id}`
        );
    },

    deleteTask(work_shop_id, project_id, { id }) {
        return axios.delete(
            `/api/${work_shop_id}/projects/${project_id}/tasks/${id}`
        );
    },

    updateProjectTaskSorting(
        work_shop_id,
        project_id,
        to_project_task_id,
        task_id
    ) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/tasks/sorting/${task_id}`,
            {
                to_project_task_id
            }
        );
    },

    updateProjectTaskClosed(work_shop_id, project_id, task_id, status) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/tasks/closed/${task_id}`,
            {
                status
            }
        );
    },

    getSubTasksPinnedSorting(work_shop_id, project_id, task_id, data = {}) {
        let request = new GetDataRequest({
            perPage: 40,
            sorting: [
                new DataSorting(
                    0,
                    "project_sub_task_pinned_sortings.sorting",
                    "asc"
                )
            ]
        });
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/${task_id}/sub-tasks/pinned-sorting`,
            {
                params: { ...data, ...request.data, perPage: request.per_page }
            }
        );
    },

    moveSubTaskPinnedSorting(
        work_shop_id,
        project_id,
        task_id,
        sub_task_id,
        { pinned_id, to_pinned_id }
    ) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/${task_id}/sub-tasks/${sub_task_id}/pinned-sorting/move/${pinned_id}`,
            { to_item: to_pinned_id }
        );
    },

    pinSubTask(work_shop_id, project_id, task_id, sub_task_id) {
        return axios.post(
            `/api/${work_shop_id}/projects/${project_id}/${task_id}/sub-tasks/${sub_task_id}/pinned-sorting/store`,
            { status: true }
        );
    },

    unPinSubTask(work_shop_id, project_id, task_id, sub_task_id, pinned_id) {
        return axios.delete(
            `/api/${work_shop_id}/projects/${project_id}/${task_id}/sub-tasks/${sub_task_id}/pinned-sorting/${pinned_id}`
        );
    },

    setInitialStatusTask(work_shop_id, project_id, task_id, data = {}) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/tasks/status/initial/${task_id}`,
            data
        );
    },

    setPauseStatusTask(work_shop_id, project_id, task_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/tasks/pause/${task_id}`
        );
    },

    setContinueStatusTask(work_shop_id, project_id, task_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/tasks/continue/${task_id}`
        );
    },

    setFinalStatusTask(work_shop_id, project_id, task_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/tasks/status/final/${task_id}`
        );
    },

    setPomodoroStartTask(work_shop_id, project_id, task_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/tasks/pomodoro-start/${task_id}`
        );
    },

    setPomodoroPauseTask(work_shop_id, project_id, task_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/tasks/pomodoro-pause/${task_id}`
        );
    },

    setPomodoroContinueTask(work_shop_id, project_id, task_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/tasks/pomodoro-continue/${task_id}`
        );
    },

    setPomodoroCancelTask(work_shop_id, project_id, task_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/tasks/pomodoro-cancel/${task_id}`
        );
    },

    setPomodoroFinishTask(work_shop_id, project_id, task_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/tasks/pomodoro-finish/${task_id}`
        );
    },

    getProjectTaskPriorities(
        work_shop_id,
        project_id,
        { current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/task-priorities/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },

    getProjectTaskPriority(work_shop_id, project_id, { id }) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/task-priorities/${id}`
        );
    },

    deleteProjectTaskPriority(work_shop_id, project_id, { id }) {
        return axios.delete(
            `/api/${work_shop_id}/projects/${project_id}/task-priorities/${id}`
        );
    },

    getProjectTaskStatuses(
        work_shop_id,
        project_id,
        { current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/task-statuses/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },

    getProjectTaskStatus(work_shop_id, project_id, { id }) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/task-statuses/${id}`
        );
    },

    projectTaskStatusUpdateSorting(
        work_shop_id,
        project_id,
        status_id,
        sorting
    ) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/task-statuses/sorting/${status_id}`,
            { sorting }
        );
    },

    projectTaskStatusSetDefault(work_shop_id, project_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/task-statuses/default/${status_id}`
        );
    },

    projectTaskStatusToggleActive(work_shop_id, project_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/task-statuses/active/${status_id}`
        );
    },

    projectTaskStatusToggleRequiredComment(work_shop_id, project_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/task-statuses/required-comment/${status_id}`
        );
    },

    projectTaskStatusToggleWaitingApprove(work_shop_id, project_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/task-statuses/approve/${status_id}`
        );
    },

    projectTaskStatusSetInitial(work_shop_id, project_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/task-statuses/initial/${status_id}`
        );
    },

    projectTaskStatusSetFinal(work_shop_id, project_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/task-statuses/final/${status_id}`
        );
    },

    projectTaskStatusSetInReview(work_shop_id, project_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/task-statuses/in-review/${status_id}`
        );
    },

    deleteProjectTaskStatus(work_shop_id, project_id, { id }) {
        return axios.delete(
            `/api/${work_shop_id}/projects/${project_id}/task-statuses/${id}`
        );
    },

    getProjectTaskTypes(
        work_shop_id,
        project_id,
        { current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/task-types/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },

    getProjectTaskType(work_shop_id, project_id, { id }) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/task-types/${id}`
        );
    },

    deleteProjectTaskType(work_shop_id, project_id, { id }) {
        return axios.delete(
            `/api/${work_shop_id}/projects/${project_id}/task-types/${id}`
        );
    },

    getSubTasks(
        work_shop_id,
        project_id,
        task_id,
        { current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/${task_id}/sub-tasks/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },

    getSubTask(work_shop_id, project_id, task_id, { id }) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/${task_id}/sub-tasks/${id}`
        );
    },

    deleteSubTask(work_shop_id, project_id, task_id, { id }) {
        return axios.delete(
            `/api/${work_shop_id}/projects/${project_id}/${task_id}/sub-tasks/${id}`
        );
    },

    updateSubTask(work_shop_id, project_id, task_id, { id, data }) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/${task_id}/sub-tasks/${id}`,
            { ...data }
        );
    },

    setFinishedSubTask(work_shop_id, project_id, task_id, { id, data }) {
        return axios.put(
            `/api/${work_shop_id}/projects/${project_id}/${task_id}/sub-tasks/state/${id}`,
            { ...data }
        );
    },

    getSubTaskChildren(work_shop_id, project_id, task_id, sub_task_id) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/${task_id}/sub-tasks/children/${sub_task_id}`
        );
    },

    getTaskTimes(
        work_shop_id,
        project_id,
        task_id,
        { current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/${task_id}/task-times/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },

    getTaskTime(work_shop_id, project_id, task_id, { id }) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/${task_id}/task-times/${id}`
        );
    },

    deleteTaskTime(work_shop_id, project_id, task_id, { id }) {
        return axios.delete(
            `/api/${work_shop_id}/projects/${project_id}/${task_id}/task-times/${id}`
        );
    },

    getProjectRoles(
        work_shop_id,
        project_id,
        { current_page = 1, per_page = 20 }
    ) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/roles/${current_page}/${per_page}`
        );
    },

    getUserForProject(work_shop_id, { current_page = 1, per_page = 20 }) {
        return axios.get(
            `/api/${work_shop_id}/users-for-project/${current_page}/${per_page}`
        );
    },

    getProjectMembers(
        work_shop_id,
        project_id,
        { current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/members/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },

    getProjectMember(work_shop_id, project_id, user_id, member_id) {
        return axios.get(
            `/api/${work_shop_id}/projects/${project_id}/members/show/${user_id}/${member_id}`
        );
    },

    deleteProjectMember(work_shop_id, project_id, user_id, member_id) {
        return axios.delete(
            `/api/${work_shop_id}/projects/${project_id}/members/${user_id}/${member_id}`
        );
    }
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select-field",
    _vm._g(
      _vm._b(
        {
          attrs: {
            title: _vm.$t("pages.taxonomies.titles"),
            form: _vm.form,
            name: _vm.name,
            multiple: _vm.multiple,
            label: "name",
            options: _vm.options,
            taggable: "",
          },
          on: { search: _vm.onSearch },
          scopedSlots: _vm._u([
            {
              key: "no-options",
              fn: function (ref) {
                var search = ref.search
                var searching = ref.searching
                return [
                  searching
                    ? [
                        _vm._v(
                          _vm._s(_vm.$t("pages.taxonomies.no_options_founded"))
                        ),
                      ]
                    : _c("em", { staticStyle: { opacity: "0.5" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("pages.taxonomies.taxonomy_search"))
                        ),
                      ]),
                ]
              },
            },
            {
              key: "option",
              fn: function (ref) {
                var id = ref.id
                var name = ref.name
                return [_c("span", [_vm._v(_vm._s(name))])]
              },
            },
          ]),
        },
        "select-field",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
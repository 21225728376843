var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-my-modal", {
    attrs: {
      modalTitle: _vm.$t("pages.expenses.receipt.create_receipt_title"),
      "show-progress": _vm.showProgress,
      "show-modal": _vm.showModal,
      saveOnEnter: true,
    },
    on: _vm._d({}, [
      _vm.saveEvent,
      _vm.onSaveButtonClicked,
      _vm.closeEvent,
      _vm.onCloseButtonClicked,
    ]),
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c("form", { attrs: { role: "form" } }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c("expense-date-field", {
                        attrs: {
                          form: _vm.form,
                          name: "dateExpense",
                          id: "receipt-date",
                          required: true,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _c("currency-field", {
                        ref: "currency",
                        attrs: {
                          form: _vm.form,
                          required: true,
                          name: "currency",
                          id: "receipt-currency",
                          initWhenCreated: false,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-4" },
                    [
                      _vm.showRate
                        ? _c("expense-rate-field", {
                            attrs: {
                              form: _vm.form,
                              required: true,
                              name: "rate",
                              id: "receipt-rate",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("provider-field", {
                        attrs: {
                          form: _vm.form,
                          name: "client",
                          id: "receipt-provider",
                          showCreationButton: true,
                          ajaxSearching: true,
                          required: true,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("account-list-field", {
                        ref: "accounts",
                        attrs: {
                          id: "receipt-create-account-field",
                          form: _vm.form,
                          name: "account",
                          title: _vm.$t("pages.accounts.title"),
                          "account-type": _vm.accountType,
                          initWhenCreated: false,
                          required: true,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("line-name-field", {
                        attrs: {
                          form: _vm.form,
                          name: "name",
                          id: "manage-free-line-name-receipt",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col md-3" },
                    [
                      _c("line-price-field", {
                        attrs: {
                          form: _vm.form,
                          name: "price",
                          id: "receipt-free-line-price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col md-2" },
                    [
                      _c("line-quantity-field", {
                        attrs: {
                          form: _vm.form,
                          name: "quantity",
                          id: "receipt-free-line-quantity",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("discount-type-field", {
                        attrs: {
                          form: _vm.form,
                          name: "discountType",
                          id: "receipt-free-line-discount-type",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col md-6" },
                    [
                      _c("line-discount-field", {
                        attrs: {
                          form: _vm.form,
                          name: "discount",
                          id: "receipt-free-line-discount",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("project-field", {
                        attrs: {
                          form: _vm.form,
                          name: "project",
                          id: "receipt-create-project-field",
                          ajaxSearching: true,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("taxonomy-field", {
                        attrs: {
                          form: _vm.form,
                          name: "taxonomies",
                          id: "receipt-taxonomy",
                          "taxonomy-type": _vm.taxonomyType,
                          ajaxSearching: true,
                          multiple: true,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("expense-refund-type-field", {
                        ref: "refundType",
                        attrs: {
                          form: _vm.form,
                          name: "refundType",
                          id: "expense-refund-type",
                          initWithDefault: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _c("expense-refund-value-field", {
                        attrs: {
                          form: _vm.form,
                          name: "refundValue",
                          id: "expense-refund-type",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <select-field
    :title="$t('pages.orders.discount_type')"
    label="name"
    :form="form"
    :name="name"
    :options="options"
    v-bind="$attrs"
    v-on="$listeners"
    :required="required"
  />
</template>

<script>
import { SelectField, BaseListField } from "../../../components/form";

export default {
  extends: BaseListField,
  components: {
    SelectField,
  },
  data() {
    return {
      loadOptions: () => {
        this.options = [
          {
            id: 1,
            name: this.$t("pages.orders.discount_type_fix_sum"),
          },
          {
            id: 3,
            name: this.$t("pages.orders.discount_type_fix_sum_by_one"),
          },
          {
            id: 2,
            name: this.$t("pages.orders.discount_type_percent"),
          },
        ];
      },
    };
  },
};
</script>

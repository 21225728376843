var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "treeselect",
    _vm._g(
      _vm._b(
        {
          attrs: { options: _vm.options, placeholder: _vm.$t("datetime.year") },
          model: {
            value: _vm.year,
            callback: function ($$v) {
              _vm.year = $$v
            },
            expression: "year",
          },
        },
        "treeselect",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import ProviderField from "./ProviderField.vue";
import ProjectField from "./ProjectField.vue";
import CategoryArticleFieldComponent from "./CategoryArticleFieldComponent.vue";
import CategoryServiceFieldComponent from "./CategoryServiceFieldComponent.vue";
import CurrencyField from "./CurrencyField.vue";
import TaxonomyField from "./TaxonomyField.vue";
import TagComponent from "./TagComponent.vue";
import ForemanStatusFieldComponent from "./ForemanStatusFieldComponent.vue";
import ClientAddressFieldComponent from "./ClientAddressFieldComponent.vue";
import DiscountField from "./DiscountField.vue";
import DiscountTypeField from "./DiscountTypeField.vue";
import ExpenseField from "./ExpenseField.vue";
import YearField from "./YearField.vue";

const ON_CHANGE_CATEGORY_ITEM_EVENT = "on-change-category-item-event";

export {
    ProjectField,
    ProviderField,
    CategoryArticleFieldComponent,
    CategoryServiceFieldComponent,
    CurrencyField,
    TaxonomyField,
    TagComponent,
    ForemanStatusFieldComponent,
    ClientAddressFieldComponent,
    DiscountField,
    DiscountTypeField,
    ExpenseField,
    YearField,
    ON_CHANGE_CATEGORY_ITEM_EVENT,
};

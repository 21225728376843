var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "p-1 m-1 text-center",
      style: {
        color: "black",
        "background-color": "#f0f0f0",
        border: "2px solid rgba(60, 60, 60, 0.26)",
        "font-size": "1rem",
        "font-weight": 400,
        "border-radius": "4px",
      },
    },
    [_vm._v(_vm._s(_vm.title))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
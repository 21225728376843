<template>
  <div class="card" :class="{ 'sk-loading': showSpinner}">
    <div class="sk-spinner sk-spinner-double-bounce" v-if="showSpinner">
      <div class="sk-double-bounce1"></div>
      <div class="sk-double-bounce2"></div>
    </div>

    <div class="card-body">
      <account-by-line-toogle-field :form="form" name="accountByLine"></account-by-line-toogle-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Form } from "../../../components/form";
import { AccountByLineToogleField } from "../fields";
import { INCOME_CONTROL_SIDEBAR_CHANGED_ACCOUNT } from "../../events";

export default {
  props: {
    accountByLine: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    AccountByLineToogleField,
  },
  data() {
    return {
      showSpinner: false,
      form: new Form({
        accountByLine: this.accountByLine,
      }),
    };
  },
  watch: {
    "form.accountByLine"(newVal) {
      EventBus.fire(INCOME_CONTROL_SIDEBAR_CHANGED_ACCOUNT, {
        accountByLine: newVal,
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
  },
};
</script>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select-field",
    _vm._g(
      _vm._b(
        {
          attrs: {
            title: _vm.$t("pages.expenses.refund_type"),
            label: "name",
            options: _vm.options,
            multiple: _vm.multiple,
            form: _vm.form,
            name: _vm.name,
          },
          scopedSlots: _vm._u([
            {
              key: "no-options",
              fn: function () {
                return [_vm._v(_vm._s(_vm.$t("fields.no_options")))]
              },
              proxy: true,
            },
          ]),
        },
        "select-field",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default class ProjectTaskMember {
    parse(data) {    
        this.id = parseInt(data.id);
        this.name = data.name;
        this.avatar = data.avatar;
        this.role_name = data.role_name;
        this.role_type = parseInt(data.role_type);

        return this;
    }

    get isOwner() {
        return this.role_type === 1;
    }

    get isExecutor() {
        return this.role_type === 2;
    }

    get isResponsible() {
        return this.role_type === 3;
    }

    //observer
    get isFollower() {
        return this.role_type === 4;
    }
}


<template>
  <div v-if="!isNaN(val)">
    <small class="badge bg-success">{{ `${val}%` }}</small>
    <slot name="body"></slot>
  </div>
</template>

<script>
export default {
  name: "MyVueProgressInline",
  props: {
    minValue: {
      type: [Number],
      default: 0,
    },
    maxValue: {
      type: [Number],
      default: 100,
    },
    currentValue: {
      type: [Number],
      required: true,
    },
  },
  computed: {
    val() {
      return Math.round((this.currentValue / this.maxValue) * 100);
    },
  },
};
</script>

<style>
</style>
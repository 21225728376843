var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showFastMenu
    ? _c("div", { staticClass: "dropdown" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "dropdown-menu",
            attrs: { "aria-labelledby": "dropDownFastMenu" },
          },
          [
            _vm.showOrder
              ? _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item",
                        attrs: {
                          to: {
                            name: "orders-create",
                            params: { work_shop_id: _vm.currentWorkShopId },
                          },
                        },
                      },
                      [
                        _c("span", [
                          _c("i", { staticClass: "nav-icon fas fa-folder" }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("pages.orders.create_order")) +
                              "\n        "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showExpense
              ? _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: {
                      to: {
                        name: "expenses-create",
                        params: { work_shop_id: _vm.currentWorkShopId },
                      },
                    },
                  },
                  [
                    _c("span", [
                      _c("i", {
                        staticClass: "nav-icon fas fa-hand-holding-usd",
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("pages.expenses.create_expense")) +
                          "\n      "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showIncome
              ? _c(
                  "router-link",
                  {
                    staticClass: "dropdown-item",
                    attrs: {
                      to: {
                        name: "incomes-create",
                        params: { work_shop_id: _vm.currentWorkShopId },
                      },
                    },
                  },
                  [
                    _c("span", [
                      _c("i", {
                        staticClass: "nav-icon fas fa-money-bill-alt",
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("pages.incomes.create_income")) +
                          "\n      "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showExpense
              ? _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: { click: _vm.showCreateReceiptModalWindow },
                  },
                  [
                    _c("i", { staticClass: "fa fa-plus" }),
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$t("pages.expenses.receipt.create_receipt")
                        ) +
                        "\n    "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "nav-link",
        attrs: {
          id: "dropDownFastMenu",
          "data-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [_c("i", { staticClass: "fas fa-plus-square" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
import axios from "axios";

export default {
    getAccounts(
        work_shop_id,
        account_type,
        { current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/accounts/${account_type}/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },

    getAccount(work_shop_id, { id }) {
        return axios.get(`/api/${work_shop_id}/accounts/${id}`);
    },

    deleteAccount(work_shop_id, { id }) {
        return axios.delete(`/api/${work_shop_id}/accounts/${id}`);
    },

    getAccountChildren(work_shop_id, tag_id) {
        return axios.get(`/api/${work_shop_id}/accounts/children/${tag_id}`);
    },

    updateAccount(work_shop_id, { id, data }) {
        return axios.put(`/api/${work_shop_id}/accounts/${id}`, { ...data });
    },

    updateRegular(work_shop_id, { id, is_regular }) {
        return axios.put(`/api/${work_shop_id}/accounts/regular/${id}`, {
            is_regular
        });
    },

    getAccountSumStatsByDate(
        work_shop_id,
        account_type,
        date_group_by,
        { data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/accounts/sum-stats-by-date/${account_type}/${date_group_by}`,
            { params: { ...data } }
        );
    }
};

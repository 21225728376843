var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !isNaN(_vm.val)
    ? _c(
        "div",
        { style: { width: "" + _vm.progressWidth } },
        [
          _c("div", { staticClass: "progress progress-sm" }, [
            _c("div", {
              staticClass: "progress-bar bg-green",
              style: { width: _vm.val + "%" },
              attrs: {
                role: "progressbar",
                "aria-volumenow": "12",
                "aria-volumemin": _vm.minValue,
                "aria-volumemax": _vm.maxValue,
              },
            }),
          ]),
          _vm._v(" "),
          _vm._t("description", function () {
            return [
              _c("small", [
                _vm._v(
                  " " + _vm._s(_vm.val + "%") + " " + _vm._s(_vm.valDescription)
                ),
              ]),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
// work-shops
export const WORK_SHOP_SHOW_CREATE_MODAL = "show-create-modal-work-shop";
export const WORK_SHOP_SHOW_EDIT_MODAL = "show-edit-modal-work-shop";
export const WORK_SHOP_SHOW_REMOVE_MODAL = "show-remove-modal-work-shop";
export const WORK_SHOP_INFO_UPDATED = "on-work-shop-info-updated";
export const WORK_SHOP_SHOW_FILTERS_MODAL = "show-work-shop-filters-model";

// discount-cards
export const DISCOUNT_CARD_SHOW_CREATE_MODAL =
    "discount-card-show-create-modal";
export const DISCOUNT_CARD_SHOW_EDIT_MODAL = "discount-card-show-edit-modal";
export const DISCOUNT_CARD_SHOW_REMOVE_MODAL =
    "discount-card-show-remove-modal";
export const DISCOUNT_CARD_CREATED = "on-discount-card-created";
export const DISCOUNT_CARD_INFO_UPDATED = "on-discount-card-info-updated";

export const DELIVERY_DATE_SHOW_CREATE_MODAL =
    "delivery-date-show-create-modal";
export const DELIVERY_DATE_SHOW_EDIT_MODAL = "delivery-date-show-edit-modal";
export const DELIVERY_DATE_SHOW_REMOVE_MODAL =
    "delivery-date-show-remove-modal";
export const DELIVERY_DATE_INFO_UPDATED = "on-delivery-date-info-updated";

//clients
export const CLIENT_SHOW_CREATE_MODAL = "client-show-create-modal";
export const CLIENT_SHOW_EDIT_MODAL = "client-show-edit-modal";
export const CLIENT_SHOW_CLIENT_INFO_MODAL = "client-show-client-info-modal";
export const CLIENT_SHOW_REMOVE_MODAL = "client-show-remove-modal";
export const CLIENT_CREATED = "on-client-created";
export const CLIENT_INFO_UPDATED = "on-client-info-updated";
export const SHOW_CLIENT_FILTERS_MODAL = "show-client-filters-model";
export const CLIENT_ADDRESS_SHOW_CREATE_MODAL =
    "client-address-show-create-modal";
export const CLIENT_ADDRESS_SHOW_EDIT_MODAL = "client-address-show-edit-modal";
export const CLIENT_ADDRESS_SHOW_REMOVE_MODAL =
    "client-address-show-remove-modal";
export const CLIENT_ADDRESS_CREATED = "on-client-address-created";
export const CLIENT_ADDRESS_INFO_UPDATED = "client-address-info-updated";

//foremen
export const FOREMAN_SHOW_CREATE_MODAL = "foreman-show-create-modal";
export const FOREMAN_SHOW_EDIT_MODAL = "foreman-show-edit-modal";
export const FOREMAN_SHOW_INFO_MODAL = "foreman-show-info-modal";
export const FOREMAN_INFO_UPDATED = "on-foreman-info-updated";
export const FOREMAN_SHOW_REMOVE_MODAL = "foreman-show-remove-modal";
export const FOREMAN_SHOW_ADD_SERVICES_MODAL =
    "foreman-show-add-services-modal";
export const FOREMAN_INFO_SERVICES_UPDATED = "on-foreman-info-services-updated";
export const FOREMAN_SERVICES_SHOW_REMOVE_MODAL =
    "foreman-services-show-remove-modal";

export const FOREMAN_SHOW_ADD_MY_SERVICES_MODAL =
    "foreman-show-add-my-services-modal";
export const FOREMAN_INFO_MY_SERVICES_UPDATED =
    "on-foreman-info-my-services-updated";
export const FOREMAN_MY_SERVICES_SHOW_REMOVE_MODAL =
    "foreman-my-services-show-remove-modal";

export const FOREMAN_FOR_CLIENT_SHOW_SERVICES_MODAL =
    "foreman-for-client-show-services-modal";

//couriers
export const COURIER_SHOW_CREATE_MODAL = "courier-show-create-modal";
export const COURIER_SHOW_EDIT_MODAL = "courier-show-edit-modal";
export const COURIER_SHOW_INFO_MODAL = "courier-show-info-modal";
export const COURIER_SHOW_REMOVE_MODAL = "courier-show-remove-modal";
export const COURIER_INFO_UPDATED = "on-courier-info-updated";

//services
export const SERVICE_SHOW_CREATE_MODAL = "service-show-create-modal";
export const SERVICE_SHOW_EDIT_MODAL = "service-show-edit-modal";
export const SERVICE_SHOW_REMOVE_MODAL = "service-show-remove-modal";
export const SERVICE_INFO_UPDATED = "on-services-info-updated";
export const SHOW_SERVICE_FILTERS_MODAL = "on-show-service-filters-modal";
export const SERVICE_SHOW_FILTERS_MODAL = "service-show-filters-model";

//articles
export const ARTICLE_SHOW_CREATE_MODAL = "article-show-create-modal";
export const ARTICLE_SHOW_EDIT_MODAL = "article-show-edit-modal";
export const ARTICLE_SHOW_REMOVE_MODAL = "article-show-remove-modal";
export const ARTICLE_INFO_UPDATED = "on-articles-info-updated";
export const ARTICLE_SHOW_FILTERS_MODAL = "article-show-filters-model";
//accounts
export const ACCOUNT_SHOW_CREATE_MODAL = "account-show-create-modal";
export const ACCOUNT_SHOW_EDIT_MODAL = "account-show-edit-modal";
export const ACCOUNT_SHOW_REMOVE_MODAL = "account-show-remove-modal";
export const ACCOUNT_INFO_UPDATED = "on-account-info-updated";
export const ACCOUNT_SHOW_FILTERS_MODAL = "account-show-filters-model";
export const ACCOUNT_SHOW_CREATE_ITEM_MODAL = "account-show-create-item-modal";
export const ACCOUNT_FILTERS_INFO_UPDATED = "account-filters-info-updated";
export const ACCOUNT_INIT_FILTERS_MODAL_AND_EXECUTE =
    "account-init-filters-modal-and-execute";
export const ACCOUNT_SUM_WIDGET_UPDATE = "on-account-su-widget-info-update";

//users
export const USER_SHOW_CREATE_MODAL = "user-show-create-modal";
export const USER_SHOW_EDIT_MODAL = "user-show-edit-modal";
export const USER_SHOW_REMOVE_MODAL = "user-show-remove-modal";
export const USER_INFO_UPDATED = "on-user-info-updated";
export const USER_SHOW_FILTERS_MODAL = "user-show-filters-model";

//tags
export const TAG_SHOW_CREATE_MODAL = "tag-show-create-modal";
export const TAG_SHOW_EDIT_MODAL = "tag-show-edit-modal";
export const TAG_SHOW_REMOVE_MODAL = "tag-show-remove-modal";
export const TAG_SHOW_CREATE_ITEM_MODAL = "tag-show-create-item-modal";
export const TAG_INFO_UPDATED = "on-tags-info-updated";

//towns
export const TOWN_SHOW_CREATE_MODAL = "town-show-create-modal";
export const TOWN_SHOW_EDIT_MODAL = "town-show-edit-modal";
export const TOWN_SHOW_REMOVE_MODAL = "town-show-remove-modal";
export const TOWN_INFO_UPDATED = "on-towns-info-updated";

//metro stations
export const METRO_STATION_SHOW_CREATE_MODAL =
    "metro-station-show-create-modal";
export const METRO_STATION_SHOW_EDIT_MODAL = "metro-station-show-edit-modal";
export const METRO_STATION_SHOW_REMOVE_MODAL =
    "metro-station-show-remove-modal";
export const METRO_STATION_INFO_UPDATED = "on-metro-stations-info-updated";

// orders
export const SHOW_ORDER_SERVICE_LINE_MODAL = "show-order-service-line-model";
export const ORDER_SERVICE_LINE_UPDATED = "order-service-line-updated";
export const SHOW_ORDER_ARTICLE_LINE_MODAL = "show-order-article-line-model";
export const SHOW_ORDER_FILTERS_MODAL = "show-order-filters-model";
export const SHOW_ORDER_TRANSFER_SERVICE_TO_FOREMAN =
    "show-order-transfer-service-to-foreman";
export const ORDER_ARTICLE_LINE_UPDATED = "order-article-line-updated";
export const ORDER_SHOW_REMOVE_MODAL = "order-show-remove-modal";
export const ORDER_INFO_UPDATED = "order-info-updated";
export const ORDER_STATUS_SHOW_CREATE_MODAL = "order-status-show-create-modal";
export const ORDER_STATUS_SHOW_EDIT_MODAL = "order-status-show-edit-modal";
export const ORDER_STATUS_SHOW_REMOVE_MODAL = "order-status-show-remove-modal";
export const ORDER_STATUS_INFO_UPDATED = "on-order-status-info-updated";
export const ORDER_STATUS_SHOW_HISTORY_MODAL = "town-show-create-modal";
export const ORDER_CREATE_CONTROL_SIDEBAR_OPEN =
    "order-create-control-sidebar-open";
export const ORDER_CONTROL_SIDEBAR_CHANGED_FOREMAN =
    "order-control-sidebar-changed-foreman";

// expenses
export const SHOW_EXPENSE_LINE_MODAL = "show-expense-line-model";
export const EXPENSE_LINE_UPDATED = "expense-service-line-updated";
export const SHOW_EXPENSE_FILTERS_MODAL = "show-expense-filters-modal";
export const EXPENSE_SHOW_REMOVE_MODAL = "expense-show-remove-modal";
export const EXPENSE_INFO_UPDATED = "expense-info-updated";
export const EXPENSE_STATUS_SHOW_CREATE_MODAL =
    "expense-status-show-create-modal";
export const EXPENSE_STATUS_SHOW_EDIT_MODAL = "expense-status-show-edit-modal";
export const EXPENSE_STATUS_SHOW_REMOVE_MODAL =
    "expense-status-show-remove-modal";
export const EXPENSE_STATUS_INFO_UPDATED = "on-expense-status-info-updated";
export const EXPENSE_CREATE_CONTROL_SIDEBAR_OPEN =
    "expense-create-control-sidebar-open";
export const EXPENSE_CONTROL_SIDEBAR_CHANGED_ACCOUNT =
    "expense-control-sidebar-changed-account";
export const EXPENSE_CONTROL_SIDEBAR_CHANGED_TAXONOMIES =
    "expense-control-sidebar-changed-taxonomies";
export const EXPENSE_RECEIPT_SHOW_CREATE_MODAL =
    "expense-receipt-show-create-modal";
export const EXPENSE_REFUND_SHOW_CREATE_MODAL =
    "expense-refund-show-create-modal";

//
export const SHOW_INCOME_LINE_MODAL = "show-income-line-model";
export const INCOME_LINE_UPDATED = "income-service-line-updated";
export const SHOW_INCOME_FILTERS_MODAL = "show-income-filters-modal";
export const INCOME_SHOW_REMOVE_MODAL = "income-show-remove-modal";
export const INCOME_INFO_UPDATED = "income-info-updated";
export const INCOME_STATUS_SHOW_CREATE_MODAL =
    "income-status-show-create-modal";
export const INCOME_STATUS_SHOW_EDIT_MODAL = "income-status-show-edit-modal";
export const INCOME_STATUS_SHOW_REMOVE_MODAL =
    "income-status-show-remove-modal";
export const INCOME_STATUS_INFO_UPDATED = "on-income-status-info-updated";
export const INCOME_CREATE_CONTROL_SIDEBAR_OPEN =
    "income-create-control-sidebar-open";
export const INCOME_CONTROL_SIDEBAR_CHANGED_ACCOUNT =
    "income-control-sidebar-changed-account";

// projects
export const PROJECT_SHOW_CREATE_MODAL = "project-show-create-modal";
export const PROJECT_SHOW_EDIT_MODAL = "project-show-edit-modal";
export const PROJECT_SHOW_REMOVE_MODAL = "project-show-remove-modal";
export const PROJECT_INFO_UPDATED = "on-project-info-updated";
export const PROJECT_SHOW_FILTERS_MODAL = "project-show-filters-model";

export const PROJECT_STAGE_SHOW_CREATE_MODAL =
    "project-stage-show-create-modal";
export const PROJECT_STAGE_SHOW_EDIT_MODAL = "project-stage-show-edit-modal";
export const PROJECT_STAGE_SHOW_REMOVE_MODAL =
    "project-stage-show-remove-modal";
export const PROJECT_STAGE_INFO_UPDATED = "on-project-stage-info-updated";

//project statuses
export const PROJECT_STATUS_SHOW_CREATE_MODAL =
    "project-status-show-create-modal";
export const PROJECT_STATUS_SHOW_EDIT_MODAL = "project-status-show-edit-modal";
export const PROJECT_STATUS_SHOW_REMOVE_MODAL =
    "project-status-show-remove-modal";
export const PROJECT_STATUS_INFO_UPDATED = "on-project-status-info-updated";

//members
export const PROJECT_MEMBER_SHOW_CREATE_MODAL =
    "project-member-show-create-modal";
export const PROJECT_MEMBER_SHOW_EDIT_MODAL = "project-member-show-edit-modal";
export const PROJECT_MEMBER_SHOW_REMOVE_MODAL =
    "project-member-show-remove-modal";
export const PROJECT_MEMBER_SHOW_FILTERS_MODAL =
    "project-member-show-filters-model";
export const PROJECT_MEMBER_INFO_UPDATED = "on-project-member-info-updated";

// tasks
export const TASK_SHOW_CREATE_MODAL = "task-show-create-modal";
export const TASK_SHOW_EDIT_MODAL = "task-show-edit-modal";
export const TASK_SHOW_REMOVE_MODAL = "task-show-remove-modal";
export const TASK_INFO_UPDATED = "on-task-info-updated";
export const TASK_SHOW_FILTERS_MODAL = "task-show-filters-model";
export const TASK_FINISHED = "task-finished";
export const TASK_STARTED = "task-started";
export const TASK_CONTINUE = "task-continue";
export const TASK_PAUSED = "task-paused";

export const TASK_POMODORO_STARTED = "task-pomodoro-started";
export const TASK_POMODORO_PAUSED = "task-pomodoro-paused";
export const TASK_POMODORO_CONTINUE = "task-pomodoro-continue";
export const TASK_POMODORO_FINISHED = "task-pomodoro-finished";
export const TASK_POMODORO_CANCELED = "task-pomodoro-canceled";

// task-time
export const TASK_TIME_SHOW_CREATE_MODAL = "task-time-show-create-modal";
export const TASK_TIME_SHOW_EDIT_MODAL = "task-time-show-edit-modal";
export const TASK_TIME_SHOW_REMOVE_MODAL = "task-time-show-remove-modal";
export const TASK_TIME_INFO_UPDATED = "on-task-time-info-updated";
export const TASK_TIME_SHOW_FILTERS_MODAL = "task-time-show-filters-model";
export const TASK_TIME_FILTERS_INFO_UPDATED = "task-time-filters-info-updated";
export const TASK_TIME_INIT_FILTERS_MODAL_AND_EXECUTE =
    "task-time-init-filters-modal-and-execute";

// sub-task
export const SUB_TASK_SHOW_CREATE_MODAL = "sub-task-show-create-modal";
export const SUB_TASK_SHOW_EDIT_MODAL = "sub-task-show-edit-modal";
export const SUB_TASK_SHOW_REMOVE_MODAL = "sub-task-show-remove-modal";
export const SUB_TASK_INFO_UPDATED = "on-sub-task-info-updated";
export const SUB_TASK_SHOW_INFO_MODAL = "sub-task-show-info-modal";
export const SUB_TASK_STARTED = "sub-task-started";
export const SUB_TASK_PAUSED = "sub-task-paused";
export const SUB_TASK_FINISHED = "sub-task-finished";
export const SUB_TASK_TOGGLE_FINISHED = "sub-task-toggle-finished";
export const SUB_TASK_PINNED = "sub-task-pinned";
export const SUB_TASK_UN_PINNED = "sub-task-un-pinned";

// control sidebar
export const CONTROL_SIDEBAR_CLOSE = "control-sidebar-close";

<template>
  <color-picker-field :title="$t('pages.accounts.color')" v-bind="$attrs" v-on="$listeners" :required="true"></color-picker-field>
</template>

<script>
import { ColorPickerField } from "../../../components/form";

export default {
  components: {
    ColorPickerField
  }
};
</script>
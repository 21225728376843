<template>
  <select-field
    :title="$t('pages.expenses.refund_type')"
    label="name"
    :options="options"
    :multiple="multiple"
    :form="form"
    :name="name"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #no-options>{{ $t("fields.no_options") }}</template>
  </select-field>
</template>

<script>
import { SelectField, BaseListField } from "../../../components/form";
import {
  REFUND_TYPE_WITHOUT,
  REFUND_TYPE_FIX_SUM,
  REFUND_TYPE_PERCENT,
} from "../../constants";

export default {
  extends: BaseListField,
  components: {
    SelectField,
  },
  data() {
    return {
      loadOptions: async () => {
        this.options = [
          {
            id: REFUND_TYPE_WITHOUT,
            name: this.$t("pages.expenses.refund_type_without"),
            is_default: 1,
          },
          {
            id: REFUND_TYPE_FIX_SUM,
            name: this.$t("pages.expenses.refund_type_fix_sum"),
            is_default: 0,
          },
          {
            id: REFUND_TYPE_PERCENT,
            name: this.$t("pages.expenses.refund_type_percent"),
            is_default: 0,
          },
        ];
      },
    };
  },
};
</script>
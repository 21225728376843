<template>
  <text-area-field :title="$t('pages.incomes.line_notes')" v-bind="$attrs" v-on="$listeners"></text-area-field>
</template>

<script>
import { TextAreaField } from "../../../components/form";

export default {
  components: {
    TextAreaField
  }
};
</script>
<template>
  <select-field
    :title="$t('pages.incomes.group_by')"
    :id="id"
    :form="form"
    :name="name"
    :options="options"
  ></select-field>
</template>

<script>
import Form, { SelectField } from "../../../components/form";

import {
  EXPENSE_GROUP_BY_ACCOUNT,
  EXPENSE_GROUP_BY_PROJECT,
  EXPENSE_GROUP_BY_PROVIDER
} from "../../constants";

export default {
  components: {
    SelectField
  },
  props: {
    form: {
      type: Form,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      options: [
        {
          id: EXPENSE_GROUP_BY_ACCOUNT,
          label: this.$t("pages.incomes.group_by_account")
        },
        {
          id: EXPENSE_GROUP_BY_PROJECT,
          label: this.$t("pages.incomes.group_by_project")
        },
        {
          id: EXPENSE_GROUP_BY_PROVIDER,
          label: this.$t("pages.incomes.group_by_provider")
        }
      ]
    };
  },
  methods: {
    init() {
      if (!this.$isset(this.form[this.name])) {
        this.form[this.name] = this.options[0];
      }
    }
  }
};
</script>

<style>
</style>
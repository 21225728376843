<template>
  <span
    class="p-1 m-1 text-center"
    :style="{'color': 'black', 'background-color': '#f0f0f0', 'border': '2px solid rgba(60, 60, 60, 0.26)', 'font-size': '1rem', 'font-weight': 400, 'border-radius': '4px'}"
  >{{ title }}</span>
</template>

<script>
export default {
  name: "TagComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
<template>
  <toogle-field :title="$t('pages.expenses.use_taxonomies_by_line')" :inline="true" v-bind="$attrs" v-on="$listeners"></toogle-field>
</template>

<script>
import { ToogleField } from "../../../components/form";

export default {
  components: {
    ToogleField
  }
};
</script>
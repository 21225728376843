var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "main-sidebar sidebar-dark-primary elevation-4" },
    [
      _c(
        "router-link",
        {
          staticClass: "brand-link",
          attrs: {
            to: {
              name: "dashboard",
              params: { work_shop_id: _vm.currentWorkShopId },
            },
          },
        },
        [
          _c("img", {
            staticClass: "brand-image",
            staticStyle: {
              opacity: "0.8",
              "margin-left": "0.4rem",
              "min-height": "45px",
            },
            attrs: { src: _vm.logo, alt: "AdminLTE Logo" },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "brand-text font-weight-light" }, [
            _vm._v(_vm._s(_vm.workShopName)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sidebar" },
        [
          _c("side-bar-user-panel"),
          _vm._v(" "),
          _c("nav", { staticClass: "mt-2" }, [
            _c(
              "ul",
              {
                staticClass: "nav nav-pills nav-sidebar flex-column",
                attrs: {
                  "data-widget": "treeview",
                  role: "menu",
                  "data-accordion": "false",
                },
              },
              [
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          to: {
                            name: "dashboard",
                            params: { work_shop_id: _vm.currentWorkShopId },
                          },
                          exact: "",
                        },
                      },
                      [
                        _c("i", { staticClass: "nav-icon fas fa-home" }),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.$t("sidebar.home")))]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.projectsEnabled && _vm.hasPermission("read_project")
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "projects",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "nav-icon fas fa-project-diagram",
                            }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("sidebar.projects"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.ordersEnabled && _vm.hasPermission("read_order")
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "orders",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "nav-icon fas fa-folder" }),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(_vm.$t("sidebar.orders")))]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                (_vm.expenseIncomeEnabled || _vm.ordersEnabled) &&
                _vm.hasPermission("read_service")
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "services",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "nav-icon fas fa-clipboard-list",
                            }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("sidebar.services"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.ordersEnabled &&
                _vm.hasPermission("read_my_services") &&
                _vm.isForeman
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "foreman-my-services",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "nav-icon far fa-address-card",
                            }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("sidebar.my-services"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                (_vm.expenseIncomeEnabled || _vm.ordersEnabled) &&
                _vm.hasPermission("read_article")
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "articles",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "nav-icon fas fa-book-open",
                            }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("sidebar.articles"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                (_vm.expenseIncomeEnabled || _vm.ordersEnabled) &&
                _vm.hasPermission("read_client")
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "clients",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "nav-icon fas fa-user-tie",
                            }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("sidebar.clients"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.discountCardsEnabled &&
                _vm.hasPermission("read_discount_card")
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "discount-cards",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "nav-icon fa fa-id-card" }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("sidebar.discount-cards"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.ordersEnabled &&
                _vm.hasPermission("read_foreman") &&
                (_vm.isSuperAdmin || _vm.isAdmin || _vm.isCollaborator)
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "foremen",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "nav-icon fas fa-briefcase",
                            }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("sidebar.foremen"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.ordersEnabled &&
                _vm.hasPermission("read_foreman") &&
                _vm.isClient
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "foremen-for-clients",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "nav-icon fas fa-briefcase",
                            }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("sidebar.foremen"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.ordersEnabled && _vm.hasPermission("read_courier")
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "couriers",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "nav-icon fas fa-bus" }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("sidebar.couriers"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.ordersEnabled && _vm.hasPermission("read_delivery_date")
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "delivery-dates",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "nav-icon far fa-calendar-alt",
                            }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("sidebar.delivery_dates"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasPermission("read_super_user") ||
                _vm.hasPermission("read_user")
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "users",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "nav-icon fas fa-users-cog",
                            }),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(_vm.$t("sidebar.users")))]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.townsEnabled && _vm.hasPermission("read_town")
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "towns",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "nav-icon fas fa-building",
                            }),
                            _vm._v(" "),
                            _c("p", [_vm._v(_vm._s(_vm.$t("sidebar.towns")))]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.expenseIncomeEnabled && _vm.hasPermission("read_expense")
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "expenses",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "nav-icon fas fa-hand-holding-usd",
                            }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("sidebar.expenses"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.expenseIncomeEnabled && _vm.hasPermission("read_income")
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "incomes",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "nav-icon fas fa-money-bill-alt",
                            }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("sidebar.incomes"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.expenseIncomeEnabled && _vm.hasPermission("read_account")
                  ? _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            attrs: {
                              to: {
                                name: "accounts",
                                params: { work_shop_id: _vm.currentWorkShopId },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "nav-icon fas fa-file-invoice",
                            }),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("sidebar.accounts"))),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("li", { staticClass: "nav-item" }, [_vm._v(" ")]),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
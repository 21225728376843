var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card", class: { "sk-loading": _vm.showSpinner } },
    [
      _vm.showSpinner
        ? _c("div", { staticClass: "sk-spinner sk-spinner-double-bounce" }, [
            _c("div", { staticClass: "sk-double-bounce1" }),
            _vm._v(" "),
            _c("div", { staticClass: "sk-double-bounce2" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("account-by-line-toogle-field", {
            attrs: { form: _vm.form, name: "accountByLine" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
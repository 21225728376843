import { isset, parseDate } from "../../../../../utils/functions";

export default class TaskPomodoroTime {
    parse(data) {
        this.started = parseInt(data.started);
        this.paused = parseInt(data.paused);

        this.id = isset(data.id) ? parseInt(data.id) : null;

        this.time_start = isset(data.time_start)
            ? parseDate(data.time_start)
            : null;
        this.time_end = isset(data.time_end) ? parseDate(data.time_end) : null;
        this.time_paused = isset(data.time_paused)
            ? parseDate(data.time_paused)
            : null;

        return this;
    }

    get isStarted() {
        return this.started === 1;
    }

    get isPaused() {
        return this.paused === 1;
    }
}

<script>
import api from "../../../api/services";

import { GetDataRequest, FilterColumn } from "../../../components/table";

import CategoryField from "./CategoryField";
import { mapGetters } from "vuex";

export default {
  extends: CategoryField,
  computed: {
    ...mapGetters("auth", ["currentWorkShopId"]),
  },
  methods: {
    async loadItems(category_id) {
      const request = new GetDataRequest({
        perPage: 1000,
      });
      request.filterData = [
        new FilterColumn("services.tag_id", category_id, "="),
        new FilterColumn("services.is_active", 1, "="),
      ];
      const { data } = await api.getServices(this.currentWorkShopId, {
        ...request,
        tag_type: this.typeTag,
      });
      return data.data;
    },
  },
};
</script>
<template>
  <select-field
    :title="$t('pages.orders.address')"
    :id="id"
    :form="form"
    :name="name"
    label="address"
    :options="options"
    :required="required"
  >
    <template v-slot:no-options>
      <div class="m-1" style="opacity: 0.5">
        {{ $t("pages.clients.address.no_options_founded") }}
      </div>
    </template>
    <!-- <template #option="{ id, name, discount_card, phone, notes }">
      <h5 style="margin: 0">[{{ id }}] {{ name }}</h5>
      <div>
        {{ `${$t("pages.orders.discount_card")}: `
        }}{{
          discount_card !== null
            ? discount_card.card_number
            : $t("pages.orders.no_discount_card")
        }}
      </div>
      <div>{{ `${$t("pages.clients.phone")}: ${phone}` }}</div>
      <div v-if="notes">{{ `${$t("pages.clients.notes")}: ${notes}` }}</div>
    </template> -->
    <template
      #list-footer
      v-if="showCreationButton && hasPermission('create_client_address')"
    >
      <div
        class="text-center border-footer-field p-2"
        @click="creationButtonClicked"
      >
        <i class="fas fa-plus text-success"></i>
        {{ $t("pages.clients.address.add") }}
      </div>
    </template>
  </select-field>
</template>

<script>
import Form, { SelectField } from "../../../components/form";
import { GetDataRequest } from "../../../api/request";
import api from "../../../api/clients";
import { mapGetters } from "vuex";

import {
  CLIENT_ADDRESS_SHOW_CREATE_MODAL,
  CLIENT_ADDRESS_CREATED,
} from "../../events";

export default {
  components: {
    SelectField,
  },
  props: {
    form: {
      type: Form,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showCreationButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("auth", ["currentWorkShopId"]),
  },
  data() {
    return {
      options: [],
    };
  },
  created() {
    EventBus.listen(CLIENT_ADDRESS_CREATED, (address) => {
      this.options.push(address);
      this.form[this.name] = address;
    });
  },
  beforeDestroy() {
    EventBus.off(CLIENT_ADDRESS_CREATED);
  },
  methods: {
    async init({ client_id, address }) {
      const request = new GetDataRequest({ perPage: 2000 });
      const { data } = await api.getClientAddresses(
        this.currentWorkShopId,
        client_id,
        {
          ...request,
        }
      );
      this.options = data.data;

      if (this.$isset(address)) {
        this.form[this.name] = this.options.find((x) => x.id === address.id);
      }
    },
    creationButtonClicked() {
      if (this.$isset(this.form.client)) {
        EventBus.fire(CLIENT_ADDRESS_SHOW_CREATE_MODAL, {
          id: this.form.client.id,
        });
      }
    },
    clear() {
      this.options = [];
    },
  },
};
</script>

<style>
</style>
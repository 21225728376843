var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select-field", {
    attrs: {
      title: _vm.$t("pages.foremen.status"),
      id: _vm.id,
      form: _vm.form,
      name: _vm.name,
      label: _vm.label,
      options: _vm.options,
      multiple: _vm.multiple,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
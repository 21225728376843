var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !isNaN(_vm.val)
    ? _c(
        "div",
        [
          _c("small", { staticClass: "badge bg-success" }, [
            _vm._v(_vm._s(_vm.val + "%")),
          ]),
          _vm._v(" "),
          _vm._t("body"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
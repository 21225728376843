<template>
  <span>{{ formattedValue }}</span>
</template>

<script>
import { Format } from "../../../calc";

import { mapGetters } from "vuex";

export default {
  props: {
    val: {
      required: true,
    },
    id: {
      type: String,
      default: "",
    },
    money: {
      type: Boolean,
      default: false,
    },
    percent: {
      type: Boolean,
      default: false,
    },
    replaceZeroValue: {
      type: Boolean,
      default: false,
    },
    precision: {
      type: Number,
      default: 2,
    },
    defaultCurrency: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters("auth", ["currency"]),
    formattedValue() {
      let formatter = new Format();
      if (this.money) {
        return (
          formatter.formatMoney(this.val, this.precision) + this.currencySymbol
        );
      }
      if (this.val === 0 && this.replaceZeroValue) return "-";

      if (this.percent) {
        return formatter.formatNumber(this.val, this.precision) + "%";
      }

      return formatter.formatNumber(this.val, this.precision);
    },
    currencySymbol() {
      if (this.$isset(this.defaultCurrency)) return this.defaultCurrency.symbol;
      if (this.$isset(this.currency)) return this.currency.symbol;
      return "";
    },
  },
};
</script>
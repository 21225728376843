import axios from "axios";

export default {
    getExpenses(work_shop_id, { current_page = 1, per_page = 20, data = {} }) {
        return axios.get(
            `/api/${work_shop_id}/expenses/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },

    getExpense(work_shop_id, { id }) {
        return axios.get(`/api/${work_shop_id}/expenses/${id}`);
    },

    getRefundsGroupedByMonth(work_shop_id, { year }) {
        return axios.get(`/api/${work_shop_id}/expenses/refunds-year/${year}`);
    },

    getProfitGroupedByMonth(work_shop_id, { year }) {
        return axios.get(`/api/${work_shop_id}/expenses/profit-by/${year}`);
    },

    getPendingRefunds(work_shop_id) {
        return axios.get(`/api/${work_shop_id}/expenses/refunds-pending`);
    },

    deleteExpense(work_shop_id, { id }) {
        return axios.delete(`/api/${work_shop_id}/expenses/${id}`);
    },

    getExpenseSumStats(work_shop_id, group_by, { data = {} }) {
        return axios.get(
            `/api/${work_shop_id}/expenses/sum-stats/${group_by}`,
            { params: { ...data } }
        );
    },

    getExpenseSumStatsByDate(
        work_shop_id,
        group_by,
        date_group_by,
        { data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/expenses/sum-stats-by-date/${group_by}/${date_group_by}`,
            { params: { ...data } }
        );
    },
};

<template>
  <select-field
    :title="$t('pages.foremen.status')"
    :id="id"
    :form="form"
    :name="name"
    :label="label"
    :options="options"
    :multiple="multiple"
  ></select-field>
</template>

<script>
import { SelectField, BaseListField } from "../../../components/form";

import apiForemen from "../../../api/foremen";

import { mapGetters } from "vuex";

export default {
  extends: BaseListField,
  components: {
    SelectField,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadOptionsAjax: (searchingText) => {
        console.warn("not implemented" + searchingText);
      },
      loadOptionsByIds: (ids) => {
        console.warn("not implemented" + ids);
      },
      loadOptions: async () => {
        const { data } = await apiForemen.getForemenStatuses(
          this.currentWorkShopId
        );
        this.options = data;
      },
    };
  },
  computed: {
    ...mapGetters("lang", ["locale"]),
    ...mapGetters("auth", ["currentWorkShopId"]),
    label() {
      return `${this.locale}name`;
    },
  },
};
</script>

<style>
</style>
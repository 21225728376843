<template>
  <select-field
    :title="title"
    :form="form"
    :name="name"
    :multiple="multiple"
    label="id"
    :options="options"
    v-bind="$attrs"
    v-on="$listeners"
    @search="onSearch"
  >
    <template v-slot:no-options="{ searching }">
      <template v-if="searching">{{
        $t("pages.expenses.no_options_founded")
      }}</template>
      <em style="opacity: 0.5" v-else>{{
        $t("pages.expenses.expense_search")
      }}</em>
    </template>
    <template
      #option="{
        id,
        refundType,
        refundTotal,
        refundIncomeTotal,
        currency,
        refundBalance,
      }"
    >
      <div style="margin: 0">{{ $t("pages.expenses.id") }}: #{{ id }}</div>

      <div v-if="refundType.id != 0">
        <span>{{ refundType.name }}</span>
        <my-vue-progress-bar
          :max-value="refundTotal"
          :current-value="refundIncomeTotal"
        >
          <template v-slot:description>
            <small class="badge bg-success"
              ><formatted-number-field
                :val="refundIncomeTotal"
                :money="true"
                :default-currency="currency"
            /></small>
            <small class="badge" style="background-color: #bcbfc5">
              <formatted-number-field
                :val="refundBalance"
                :money="true"
                :default-currency="currency"
              />
            </small>
          </template>
        </my-vue-progress-bar>
      </div>
    </template>
  </select-field>
</template>

<script>
import {
  SelectField,
  BaseListField,
  FormattedNumberField,
} from "../../../components/form";
import { MyVueProgressBar } from "../../../components/progress";

import {
  DataSearching,
  DataFiltering,
  GetDataRequest,
} from "../../../api/request";
import api from "../../../api/expenses";
import { arrayWrap } from "../../../utils/functions";
import { REFUND_TYPE_FIX_SUM, REFUND_TYPE_PERCENT } from "../../constants";

export default {
  extends: BaseListField,
  props: {
    title: {
      type: [String],
      default() {
        return this.multiple
          ? this.$t("pages.expenses.titles")
          : this.$t("pages.expenses.title");
      },
    },
  },
  components: {
    SelectField,
    MyVueProgressBar,
    FormattedNumberField,
  },
  data() {
    return {
      loadOptionsAjax: (searchingText) => {
        const request = new GetDataRequest({
          searching: [new DataSearching("expenses.id", searchingText, "ILIKE")],
          filtering: [
            new DataFiltering(
              "expenses.refund_type",
              [REFUND_TYPE_PERCENT, REFUND_TYPE_FIX_SUM],
              "in"
            ),
          ],
        });
        return api.getExpenses(this.currentWorkShopId, {
          ...request,
        });
      },
      loadOptionsByIds: (ids) => {
        let filteredIds = arrayWrap(ids);

        const request = new GetDataRequest({
          filtering: [
            new DataFiltering("expenses.id", filteredIds.join(","), "in"),
          ],
          perPage: filteredIds.length,
        });
        return api.getExpenses(this.currentWorkShopId, {
          ...request,
        });
      },
      loadOptions: async () => {
        let { data } = await api.getExpenses(this.currentWorkShopId, {
          perPage: 1000,
        });
        this.options = data.data;
      },
    };
  },
};
</script>
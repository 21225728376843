var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      style: { "z-index": _vm.zindex },
      attrs: {
        id: _vm.id,
        "data-keyboard": "false",
        "data-backdrop": "static",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog",
          class: {
            "modal-dialog-scrollable": _vm.modalDialogScrollable,
            "modal-lg": _vm.modalLarge,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-content",
              class: {
                "bg-danger": _vm.modalBgDanger,
                "bg-warning": _vm.modalBgWarning,
              },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.saveOnEnterHandler.apply(null, arguments)
                },
              },
            },
            [
              _vm.showProgress
                ? _c(
                    "div",
                    {
                      staticClass:
                        "overlay d-flex justify-content-center align-items-center",
                    },
                    [_c("i", { staticClass: "fas fa-2x fa-sync fa-spin" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "modal-header" }, [
                _c("h4", { staticClass: "modal-title" }, [
                  _vm._v(_vm._s(_vm.modalTitle)),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button", "aria-label": "Close" },
                    on: { click: _vm.closeButtonClicked },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [_vm._t("body")], 2),
              _vm._v(" "),
              _vm.showSaveFooterButton || _vm.showCloseFooterButton
                ? _c("div", { staticClass: "modal-footer" }, [
                    _vm.showCloseFooterButton
                      ? _c(
                          "button",
                          {
                            staticClass: "btn",
                            class: {
                              "btn-secondary":
                                !_vm.modalBgWarning && !_vm.modalBgDanger,
                            },
                            attrs: { type: "button" },
                            on: { click: _vm.closeButtonClicked },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.closeButtonTranslation) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showSaveFooterButton
                      ? _c(
                          "button",
                          {
                            staticClass: "btn",
                            class: {
                              "btn-primary":
                                !_vm.modalBgWarning && !_vm.modalBgDanger,
                              "btn-outline-light":
                                _vm.modalBgWarning || _vm.modalBgDanger,
                            },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.saveButtonClicked.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.saveButtonTranslation) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <select-field
    :title="title"
    :id="id"
    :form="form"
    :name="name"
    label="name"
    :options="options"
    @search="onSearch"
    :multiple="multiple"
    :required="required"
  >
    <template v-slot:no-options="{ search, searching }">
      <template v-if="searching">
        <div class="m-1" style="opacity: 0.5">
          {{ $t("pages.providers.no_options_founded") }}
        </div>
      </template>
      <div style="opacity: 0.5" v-else>
        <div class="m-1">
          {{ $t("pages.providers.provider_search") }}
        </div>
      </div>
    </template>
    <template #option="{ id, name }">
      <div style="margin: 0">[{{ id }}] {{ name }}</div>
    </template>
    <template #list-footer v-if="showCreationButton">
      <div
        class="text-center border-footer-field p-2"
        @click="creationButtonClicked"
      >
        <i class="fas fa-plus text-success"></i>
        {{ $t("pages.providers.create_provider") }}
      </div>
    </template>
  </select-field>
</template>

<script>
import { SelectField, BaseListField } from "../../../components/form";

import {
  DataFiltering,
  DataSearching,
  GetDataRequest,
} from "../../../api/request";

import apiClients from "../../../api/clients";

import { CLIENT_SHOW_CREATE_MODAL, CLIENT_CREATED } from "../../events";
import { arrayWrap } from "../../../utils/functions";

export default {
  extends: BaseListField,
  components: {
    SelectField,
  },
  props: {
    showCreationButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadOptionsAjax: (searchingText) => {
        const request = new GetDataRequest({
          searching: [
            new DataSearching("clients.id", searchingText),
            new DataSearching("users.name", searchingText),
            new DataSearching("users.phone", searchingText),
            new DataSearching("users.email", searchingText),
          ],
        });
        return apiClients.getClients(this.currentWorkShopId, {
          ...request,
        });
      },
      loadOptionsByIds: (ids) => {
        let filteredIds = arrayWrap(ids);

        const request = new GetDataRequest({
          filtering: [
            new DataFiltering("clients.id", filteredIds.join(","), "in"),
          ],
          perPage: filteredIds.length,
        });
        return apiClients.getClients(this.currentWorkShopId, {
          ...request,
        });
      },
      loadOptions: () => {
        //not implemented
        console.error("not impelemented");
      },
    };
  },
  computed: {
    title() {
      return this.multiple
        ? this.$t("pages.providers.titles")
        : this.$t("pages.providers.title");
    },
  },
  created() {
    EventBus.listen(CLIENT_CREATED, (client) => {
      if (this.showCreationButton) {
        this.options.push(client);
        this.form[this.name] = client;
      }
    });
  },
  beforeDestroy() {
    EventBus.off(CLIENT_CREATED);
  },
  methods: {
    creationButtonClicked() {
      EventBus.fire(CLIENT_SHOW_CREATE_MODAL);
    },
  },
};
</script>
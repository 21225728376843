export const SERVICE_TYPE_TAG_ID = "3";
export const ARTICLE_TYPE_TAG_ID = "4";
export const ACCOUNT_TYPE_TAG_ID = "5";

//discounts
export const DISCOUNT_TYPE_FIX_SUM = 1;
export const DISCOUNT_TYPE_PERCENT = 2;
export const DISCOUNT_TYPE_FIX_SUM_BY_ONE = 3;

export const PROJECT_STATUS_IS_DRAFT = 1;
export const PROJECT_STATUS_IS_NEW = 2;
export const PROJECT_STATUS_IS_INITIAL = 3;
export const PROJECT_STATUS_IS_WAITING_APPROVE = 4;
export const PROJECT_STATUS_IS_IN_REVIEW = 5;
export const PROJECT_STATUS_IS_RETURNED = 6;
export const PROJECT_STATUS_IS_FINAL = 7;

export const ORDER_STATUS_IS_DRAFT = 1;
export const ORDER_STATUS_IS_CANCELED = 2;
export const ORDER_STATUS_IS_WAITING_COMPLETION = 3;
export const ORDER_STATUS_IS_WORKING = 4;
export const ORDER_STATUS_IS_COMPLETED = 5;
export const ORDER_STATUS_IS_NEW = 6;
export const ORDER_STATUS_IS_HANDED_OVER_DELIVERY = 7;
export const ORDER_STATUS_IS_DELIVERY_ON_THE_WAY = 8;
export const ORDER_STATUS_IS_DELIVERED = 9;
export const ORDER_STATUS_IS_FINAL = 10;
export const ORDER_STATUS_IS_AWAITING_COURIER = 11;
export const ORDER_STATUS_IS_COLLECT_ON_THE_WAY = 12;
export const ORDER_STATUS_IS_COURIER_HAS_ORDER = 13;

export const FOREMAN_STATUS_NO_ACTION = 0;
export const FOREMAN_STATUS_IS_WORKING = 1;
export const FOREMAN_STATUS_IS_PAUSED = 2;
export const FOREMAN_STATUS_IS_COMPLETED = 3;

// expenses
export const EXPENSE_ARTICLE_LINE = 1;
export const EXPENSE_SERVICE_LINE = 2;
export const EXPENSE_FREE_LINE = 3;

export const EXPENSE_GROUP_BY_ACCOUNT = 1;
export const EXPENSE_GROUP_BY_PROJECT = 2;
export const EXPENSE_GROUP_BY_PROVIDER = 3;

export const EXPENSE_DATE_GROUP_BY_DAY = 1;
export const EXPENSE_DATE_GROUP_BY_MONTH = 2;
export const EXPENSE_DATE_GROUP_BY_YEAR = 3;

export const REFUND_TYPE_WITHOUT = 0;
export const REFUND_TYPE_FIX_SUM = 1;
export const REFUND_TYPE_PERCENT = 2;

// incomes
export const INCOME_ARTICLE_LINE = 1;
export const INCOME_SERVICE_LINE = 2;
export const INCOME_FREE_LINE = 3;

export const INCOME_GROUP_BY_ACCOUNT = 1;
export const INCOME_GROUP_BY_PROJECT = 2;
export const INCOME_GROUP_BY_PROVIDER = 3;

export const INCOME_GROUP_BY_DAY = 1;
export const INCOME_GROUP_BY_MONTH = 2;
export const INCOME_GROUP_BY_YEAR = 3;

export const INCOME_DATE_GROUP_BY_DAY = 1;
export const INCOME_DATE_GROUP_BY_MONTH = 2;
export const INCOME_DATE_GROUP_BY_YEAR = 3;

// accounts
export const ACCOUNT_DATE_GROUP_BY_DAY = 1;
export const ACCOUNT_DATE_GROUP_BY_MONTH = 2;
export const ACCOUNT_DATE_GROUP_BY_YEAR = 3;

export const ACCOUNT_TYPE_CREDIT = 1;
export const ACCOUNT_TYPE_DEBIT = 2;

// group by day
export const DATE_GROUP_BY_DAY = 1;
export const DATE_GROUP_BY_MONTH = 2;
export const DATE_GROUP_BY_YEAR = 3;

export const FOREMEN_STATUS_IS_VACATION = 1;
export const FOREMEN_STATUS_IS_DAY_OFF = 2;
export const FOREMEN_STATUS_IS_WORKING = 3;

// taxonomies
export const TAXONOMY_TYPE_EXPENSE = 6;

export const COLORS = [
    "#008FFB",
    "#FEB019",
    "#775DD0",
    "#3F51B5",
    "#4CAF50",
    "#662E9B",
    "#5A2A27",
    "#2E294E",
    "#A300D6",

    "#a61c00",
    "#cc0000",
    "#e69138",
    "#f1c232",
    "#45818e",
    "#3c78d8",
    "#3d85c6",
    "#674ea7",
    "#a64d79",

    "#434343",

    "#980000",
    "#ff0000",
    "#ff9900",
    "#ffff00",
    "#00ff00",
    "#00ffff",
    "#4a86e8",
    "#0000ff",
    "#9900ff",
    "#ff00ff",

    "#e6b8af",

    "#dd7e6b",
    "#ea9999",
    "#f9cb9c",
    "#ffe599",
    "#b6d7a8",
    "#a2c4c9",
    "#a4c2f4",
    "#9fc5e8",
    "#b4a7d6",
    "#d5a6bd",

    "#cc4125",
    "#e06666",
    "#f6b26b",
    "#ffd966",
    "#93c47d",
    "#76a5af",
    "#6d9eeb",
    "#6fa8dc",
    "#8e7cc3",
    "#c27ba0",

    "#85200c",
    "#990000",
    "#b45f06",
    "#bf9000",
    "#38761d",
    "#134f5c",
    "#1155cc",
    "#0b5394",
    "#351c75",
    "#741b47",

    "#5b0f00",
    "#660000",
    "#783f04",
    "#7f6000",
    "#274e13",
    "#0c343d",
    "#1c4587",
    "#073763",
    "#20124d",
    "#4c1130",
];

export const CHART_COLORS = [
    "#EF8D5E", //third
    "#F9B560",
    "#FBD884",
    "#B2D198",
    "#68C17D",
    "#4C947E",
    "#4BC2BA",
    "#68C4EB",
    "#4CA2FF",
    "#5173BA",
    "#C29ABC",
    "#B367B1",
    "#FFA2C6",
    "#C16C95",

    "#E85D1A", //center column
    "#F9971D",
    "#F9C64F",
    "#91BE6C",
    "#28A744",
    "#016646",
    "#00A99E",
    "#2AAAE1",
    "#007AFF",
    "#07379B",
    "#A86E9E",
    "#93278F",
    "#FF7BAC",
    "#A42D65",

    "#F6BFA3", //second
    "#FBD5A4",
    "#FEE9BA",
    "#D4E5C5",
    "#A9DBB4",
    "#99C2B4",
    "#98DDD8",
    "#A9DDF3",
    "#98CAFF",
    "#9CAFD7",
    "#DCC5D9",
    "#D4AAD2",
    "#FFCADE",
    "#DBABC1",

    "#502009", // seven
    "#57340A",
    "#57461B",
    "#324226",
    "#0E3A17",
    "#002418",
    "#003B37",
    "#0D3C4E",
    "#002A58",
    "#031335",
    "#3C2738",
    "#330E32",
    "#5A2B3D",
    "#3A1024",

    "#AD4613", //fifth
    "#BA7017",
    "#F9C64F",
    "#6C8E52",
    "#1D7D35",
    "#014C35",
    "#007F76",
    "#1F80AA",
    "#005CBF",
    "#052975",
    "#7F5376",
    "#6E1D6A",
    "#BE5D82",
    "#7D224D",

    "#FAD6C6", //first column
    "#FDE6C7",
    "#FCF1D3",
    "#BFD9D0",
    "#BFE9E7",
    "#C9EAF9",
    "#BFDDFF",
    "#C1CDE7",
    "#E9DBE8",
    "#E3C9E2",
    "#FFDEE9",
    "#E9CAD9",

    "#74300D", //six
    "#7C4B10",
    "#7D6529",
    "#495F38",
    "#145422",
    "#003324",
    "#00554F",
    "#155771",
    "#013E7F",
    "#051C50",
    "#051C4E",
    "#54384F",
    "#54384F",
    "#7F3E56",
    "#531733",
];

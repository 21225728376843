<template>
  <div v-if="!isNaN(val)" :style="{ width: `${progressWidth}` }">
    <div class="progress progress-sm">
      <div
        class="progress-bar bg-green"
        role="progressbar"
        aria-volumenow="12"
        :aria-volumemin="minValue"
        :aria-volumemax="maxValue"
        :style="{ width: `${val}%` }"
      ></div>
    </div>
    <slot name="description">
      <small> {{ `${val}%` }} {{ valDescription }}</small>
    </slot>
  </div>
</template>

<script>
export default {
  name: "MyVueProgressBar",
  props: {
    minValue: {
      type: [Number],
      default: 0,
    },
    maxValue: {
      type: [Number],
      default: 100,
    },
    currentValue: {
      type: [Number],
      required: true,
    },
    width: {
      type: [Number],
      default: 0,
    },
    valDescription: {
      type: String,
      default() {
        return this.$t("progress.progress_description");
      },
    },
  },
  computed: {
    val() {
      return Math.round((this.currentValue / this.maxValue) * 100);
    },
    progressWidth() {
      return this.width > 0 ? `${this.width}px` : `100%`;
    },
  },
};
</script>

<style>
.progress {
  background: #bcbfc5;
}
</style>
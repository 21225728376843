import { mapGetters } from "vuex";
import * as modalEvents from "./modal-events";

export default {
    data() {
        return {
            showModal: false
        };
    },
    computed: {
        ...mapGetters("auth", ["currentWorkShopId"]),
        saveEvent() {
            return modalEvents.SAVE_BUTTON_CLICKED;
        },
        closeEvent() {
            return modalEvents.CLOSE_BUTTON_CLICKED;
        },
        showProgress() {
            return this.form.busy;
        }
    },
    methods: {
        onCloseButtonClicked() {
            this.showModal = false;
        }
    }
};

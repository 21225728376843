<template>
  <div class="card" :class="{ 'sk-loading': showSpinner }">
    <div class="sk-spinner sk-spinner-double-bounce" v-if="showSpinner">
      <div class="sk-double-bounce1"></div>
      <div class="sk-double-bounce2"></div>
    </div>

    <div class="card-body">
      <account-by-line-toogle-field
        :form="form"
        name="accountByLine"
      ></account-by-line-toogle-field>
      <taxonomies-by-line-toogle-field
        :form="form"
        name="taxonomiesByLine"
      ></taxonomies-by-line-toogle-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Form } from "../../../components/form";
import {
  AccountByLineToogleField,
  TaxonomiesByLineToogleField,
} from "../fields";
import {
  EXPENSE_CONTROL_SIDEBAR_CHANGED_ACCOUNT,
  EXPENSE_CONTROL_SIDEBAR_CHANGED_TAXONOMIES,
} from "../../events";

export default {
  props: {
    accountByLine: {
      type: Boolean,
      required: true,
    },
    taxonomiesByLine: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    AccountByLineToogleField,
    TaxonomiesByLineToogleField,
  },
  data() {
    return {
      showSpinner: false,
      form: new Form({
        accountByLine: this.accountByLine,
        taxonomiesByLine: this.taxonomiesByLine,
      }),
    };
  },
  watch: {
    "form.accountByLine"(newVal) {
      EventBus.fire(EXPENSE_CONTROL_SIDEBAR_CHANGED_ACCOUNT, {
        accountByLine: newVal,
      });
    },
    "form.taxonomiesByLine"(newVal) {
      EventBus.fire(EXPENSE_CONTROL_SIDEBAR_CHANGED_TAXONOMIES, {
        taxonomiesByLine: newVal,
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("auth", ["currentWorkShopId"]),
  },
};
</script>